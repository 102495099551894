import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/features/number/is-nan'; //for older version of safari iOS <=11
import 'core-js/es/'; //for older version of safari iOS <=11
import 'raf/polyfill'; //for older version of safari iOS <=11
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('dotenv').config();


ReactDOM.render(<LocalizeProvider><Router><App /></Router></LocalizeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
