import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faHistory } from '@fortawesome/free-solid-svg-icons';

const Bottom = (props) => (

  <Row>
    <Col> 
        <Button variant="link" href="/" className="w-100">
          <div><FontAwesomeIcon icon={faHome} /></div>
          <div className="d-flex align-content-center justify-content-center font-size-xs m-0">{props.translate("common.footer.button.home")}</div>
        </Button>
    </Col>
    <Col>
      <Button variant="link" href="/profile">
        <div><FontAwesomeIcon icon={faUser} /></div>
        <div className="d-flex align-content-center justify-content-center font-size-xs m-0">{props.translate("common.footer.button.profile")}</div>
      </Button>
    </Col>         
    <Col>
      <Button variant="link" href="/bookingHistory">
        <div><FontAwesomeIcon icon={faHistory} /></div>
        <div className="d-flex align-content-center justify-content-center font-size-xs m-0">{props.translate("common.footer.button.bookRequestHistory")}</div>
      </Button>
    </Col>
  </Row>


);

export default withLocalize(Bottom);
