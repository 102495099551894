import Constants from '../constants';
import { Commons } from './Commons';

const serviceUrl = Constants.API.PAY_SERVICES;

export {
  pay, check, getPayment, update
};

function pay(requestData, callback) {
  var callingService = serviceUrl+requestData.paymentType+'/pay';
  //console.log('calling '+callingService);
  Commons.post(callingService, requestData, callback);
}

function check(requestData, callback) {
  var callingService = serviceUrl+requestData.paymentType+'/check/'+requestData.paymentId;
  Commons.get(callingService, callback, requestData);
}

function update(requestData, callback) {
  var callingService = serviceUrl+'/update/'+requestData.paymentId;
  Commons.post(callingService, requestData, callback);
}

function getPayment(requestData, callback) {
  var callingService = serviceUrl+'payment/'+requestData.paymentId;
  Commons.get(callingService, callback, requestData);
}


