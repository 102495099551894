import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Header, Footer, Bottom, ManagerBottom } from './containers';
import { withLocalize, setActiveLanguage, Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Routes from './routes';
import Constants from './constants';
import { AuthServices } from './services';
import { css } from '@emotion/core';
import ClockLoader from 'react-spinners/ClockLoader';

const GlobalConstants = require('aiot-room-booking-common').constants;

const override = css`
  display: block;
  margin: 0 auto;
  width: auto;
  height: auto;
  box-sizing: content-box;
`;

ReactGA.initialize(Constants.APP_ID.GA_TRACKING_CODE);
ReactGA.pageview(window.location.pathname);

class App extends Component {
  
  constructor(props) {
    super(props);
    let sessionUserData = window.localStorage.getItem(Constants.SESSION_KEY_USER_DATA);
    //console.log('sessionUserData=>'+sessionUserData);
    let userIsLoggin = sessionUserData? true:false;
    if (sessionUserData) {
      sessionUserData = JSON.parse(sessionUserData);
    }

    this.state = {
      isLoggedIn: userIsLoggin,
      userData: sessionUserData,
      isLanguageUpdated: false,
      activeLanguage: window.localStorage.getItem(Constants.ACTIVE_LANG_CODE),
      isShowSpinner: true
    };
    
    const languages = Constants.SUPPORTED_LANGS;
    const defaultLanguage = window.localStorage.getItem(Constants.ACTIVE_LANG_CODE) || languages[0].code;
    setActiveLanguage(defaultLanguage);

    this.props.initialize({
      languages,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage
      }
    });


  }

  componentDidMount(){
    Constants.SUPPORTED_LANGS.map((lang)=>(this.loadLangData(lang.code)));
    var sessionUserData = this.state.userData;
    if (sessionUserData) {
      AuthServices.validate({token: sessionUserData._t},this.validateCallback);
      //console.log(this.state.userData.roles.filter(m => m.name === "Manager").length > 0);
    } else {
      this.setState({isShowSpinner: false});
    }
  }

  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;
    const hasLanguageChanged = prevLangCode !== curLangCode;

    if (hasLanguageChanged) {
      this.setState({ isLanguageUpdated: true, activeLanguage: curLangCode });
      window.localStorage.setItem(Constants.ACTIVE_LANG_CODE, curLangCode);
    }
  }

  validateCallback = (resp) => {
    //console.log(resp);
    if (resp.ok) {
      this.setState({isLoggedIn:true,
        isShowSpinner: false});
    } else {
      this.setState({isShowSpinner: false});
      if (this.state.userData) {
        var sessionUserData = this.state.userData;
        if (!sessionUserData.roles || sessionUserData.roles[0].slug===GlobalConstants.PERMISSIONS.TEMP) {
          //console.log(sessionUserData);
          AuthServices.login({userId:sessionUserData._id}, this.transparentLoginCallback);
        } else {
          this.handleLogout({});
        }
      }
    }
  }

  transparentLoginCallback = (resp) => {
    //console.log(resp);
    if (resp.ok) {
      let uData = this.state.userData;
      uData["_t"] = resp.data.token;
      window.localStorage.setItem(Constants.SESSION_KEY_USER_DATA, JSON.stringify(uData));
      this.setState({userData: uData,
        isShowSpinner: false});
    }
  }

  loadLangData = (langCode)=> {
	  fetch(Constants.CONTEXT_PATH+'res/lang_'+langCode+'.json?v=1')
		.then((r) => r.json())
		.then((data) =>{
			this.props.addTranslationForLanguage(data, langCode);
		})
  }

  handleLogout = event => {
    //console.log('user is going to be logged out');
    var requestData = {
      user: this.state.userData._id,
      token: this.state.userData._t
    };
    AuthServices.logout(requestData, this.postLogoutCallback);
  };

  postLogoutCallback = (resp) => {
    this.resetLoginState();
    this.props.history.push(Constants.CONTEXT_PATH+"login");
  }

  resetLoginState = () => {
    window.localStorage.removeItem(Constants.SESSION_KEY_USER_DATA);
    this.userHasLoggedIn(false);
    this.setState({userData:null,
      isShowSpinner: false});
  };

  userHasLoggedIn = loggedIn => {
    this.setState({ isLoggedIn: loggedIn });
  };

  setTempUser = (uData,trigger) => {
    if (trigger) {
      this.setState({userData:uData},trigger);
    } else {
      this.setState({userData:uData});
    }
  };

  supportUs = ({type, oid}) => {
    //console.log(oid);
    this.props.history.push(Constants.CONTEXT_PATH+"support/"+type+"/"+oid);
  }

  setSpinner = ({isShow,trigger}) => {
    if (trigger) {
      this.setState({isShowSpinner: isShow},trigger);
    } else {
      this.setState({isShowSpinner: isShow});
    }
  }

  render() {

    const childProps = {
      isLoggedIn: this.state.isLoggedIn,
      userData: this.state.userData,
      userHasLoggedIn: this.userHasLoggedIn,
      setUser: this.setTempUser,
      supportUs: this.supportUs,
      handleLogout: this.handleLogout,
      setSpinner: this.setSpinner
    };

    return (
    <div className="App"><link href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"></link>
      <header className="App-header">
      <Header state={this.state} languages={Constants.SUPPORTED_LANGS} setActiveLanguage={this.props.setActiveLanguage} logoutHandler={this.handleLogout}/>
      </header>
      <div className="App-content">

        <h4><span className="float-right"><Translate id='common.greeting'/> {this.state.userData?this.state.userData.displayName:''}</span></h4>
        <Translate>{({translate})=>(this.props.activeLanguage?<Routes childProps={{ ...childProps, translate:translate}} />:translate("common.loading"))}</Translate>
      </div>

      <div className="App-footer">
        {this.state.userData? (this.state.userData.roles.filter(m => m.name === "Manager").length > 0 ? <ManagerBottom/> : <Bottom />):<Bottom/> }
        
        <Footer /> 
      </div>
      <div className='sweet-loading'>
        <ClockLoader
          sizeUnit={"px"}
          size={150}
          css={override}
          color={'#304D50'}
          loading={this.state.isShowSpinner}
        />
      </div>

    </div>
    );
  }
}

export default withRouter(withLocalize(App));
