import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
//import Moment from 'react-moment';
import Constants from '../../constants';
import { /*UserServices,*/ RoomBookingServices } from '../../services';
import { Prompt } from "../../utils";
import { FaUniversity } from "react-icons/fa";
let paymeImg  =  '/img/icons/logo-payme.png';
let alipayImg =  '/img/icons/logo_Alipay.png';
let paypalImg =  '/img/icons/logo_Paypal.png';
let wechatImg = '/img/icons/logo_WeChat_Pay.png';

const moment = require('moment');

class BookingHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: [],
      rooms: []
    }
  }

  async componentDidMount() {
    //console.log(this.props.userData);
    this.loadRooms();
    this.loadBooking();
  }

  loadBooking = () => {
    var requestData = {
      user: this.props.userData._id
    };
    RoomBookingServices.getBooking(requestData, this.loadBookingCallback);
  }

  loadBookingCallback = (resp) => {
    if (resp!==null && resp!==undefined && resp.ok) {
      resp.data.booking.sort(function(a, b){
        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      });
      this.setState({booking: resp.data.booking});

    } else{
      Prompt.error('Error',resp.error.message,'OK');
    }
  }

  loadRooms = () => {
    let requestData = "";
    RoomBookingServices.getRoom(requestData, this.loadRoomsCallback);
  };

  loadRoomsCallback = (resp) => {
    if (resp !== null && resp !== undefined && resp.ok) {
      this.setState({
        rooms: resp.data.rooms
      });
      //console.log(this.state.rooms);
    } else {
      Prompt.error(
        this.props.translate("common.sysmsg"),
        this.props.translate("common.errMsg." + resp.error.message),
        this.props.translate("common.ok")
      );
    }
  };

  render() {

    return (
      <div className="Login"><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/login.css?t="+Constants.VERSION}></link>
        <div className="pageTitle">
          <h1>
            <Translate id="login.pageTitle" />
          </h1>
        </div>
        <h2 className="text-left font-weight-bold"><Translate id="common.footer.button.bookRequestHistory"/></h2>
        
        <ListGroup className="list-group-style">
          {this.state.booking.length === 0 ? <ListGroupItem className="px-2"><Translate id="common.errMsg.SYS_ITEM_NOT_FOUND"/></ListGroupItem> : ""}
          <Row xs={1} sm={1} md={1} lg={1}>
          {this.state.booking.map(item => (
              
              <ListGroupItem className="px-3 font-size-xs px-0">
                {<Row><h5>{this.state.rooms.filter(e => e._id === item.room).map(fe => (fe.name))}</h5></Row>}
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} className="pr-0" style={{width: "55%"}}>
                      <div><Translate id="events.request.room.address"/>: &nbsp;{this.state.rooms.filter(e => e._id === item.room).map(fe => (fe.address))}</div>
                      <div><Translate id="events.request.room.date"/>:&nbsp;{moment(item.startTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD")}</div>
                      <div><Translate id="events.starttime"/>:&nbsp;{moment(item.startTime, "YYYY-MM-DDTHH:mm").format("HH:mm")}</div>
                      <div><Translate id="events.request.room.paymentMethod"/>:&nbsp;
                      {item.paymentMethod === "bank" ? <FaUniversity/> : 
                                <img src={item.paymentMethod === "payme" ? Constants.DOMAIN + paymeImg :
                                          item.paymentMethod === "wechat" ? Constants.DOMAIN + wechatImg :
                                          item.paymentMethod === "paypal" ? Constants.DOMAIN + paypalImg :
                                          item.paymentMethod === "alipay" ? Constants.DOMAIN + alipayImg : "" } alt="paymentMethod" style={{ width: "25px" }} />}
                      </div>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} className="pl-0">
                      <div><Translate id="events.request.room.orderId"/>:<br/>&nbsp;{item.bookingRef}</div>
                      <div><Translate id="events.request.room.distinct"/>:&nbsp;{this.state.rooms.filter(e => e._id === item.room).map(fe => (fe.district))}</div>

                      <div><Translate id="events.request.room.orderStatus"/>:&nbsp;{  ( moment(moment(item.startTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DD")).isBefore( moment(new Date()).format("YYYY-MM-DD") ) ) ? <Translate id="events.request.room.orderStatusDetails.outDated"/> : <Translate id="events.request.room.orderStatusDetails.waitForConfirm"/> }</div>
                      <div><Translate id="events.endtime"/>:&nbsp;{moment(item.endTime, "YYYY-MM-DDTHH:mm").format("HH:mm")}</div>
                      <div><Translate id="events.request.room.total"/>:&nbsp;HKD${item.totalPrice}</div>
                    </Col>
                </Row>
              </ListGroupItem>
          ))}
          </Row>
        </ListGroup>


        <Row className="mb-5"></Row>
        <Row className="mb-5"></Row>
        <Row className="mb-5"></Row>

      </div>
    );
  }
};

export default withLocalize(BookingHistory);