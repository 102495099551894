import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Alert,/* ButtonGroup,*/ Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Constants from '../../constants';
import { UserServices } from '../../services';
import emailValidator from 'email-validator';
import { Prompt } from '../../utils';


class ForgotPassword extends Component {
    constructor(props) {
      super(props);

      this.state = {
        supportRequestId: this.props.match.params.supportRequestId,
        redirectToEvents: false,
        email: { value: '', vs: null, errormsg: '' },
        isLoading: false

      }
    }
    

    componentDidMount(){
        //this.activate();
    }

    componentWillUnmount = () => {
      //clearTimeout(this.timeout);
    }

    validateEmail() {
      var state = this.state;
      if ((this.state.email.value.length <= 0) || !emailValidator.validate(this.state.email.value)) {
        state.email.vs = 'error';
        state.email.errormsg = <Translate id="password.errMsg.invalidEmail" />;
        this.setState(state);
        return false;
      } else {
        state.email.vs = null;
        state.email.errormsg = '';
        this.setState(state);
        return true;
      }
    }


    handleChange = event => {
      var state = this.state;

      state[event.target.id].value = event.target.value;
      this.setState(state);

      switch (event.target.id) {
  
        case 'email':
          return this.validateEmail();
  
        default:
          break;
      }
    };

    validateForm() {
      return this.validateEmail();
    }

    handleSubmit = event => {
      event.preventDefault();
      if (this.validateForm()) {
        var state = this.state;
        state.isLoading = true;
        this.setState(state);

        var requestData = '?email=' + this.state.email.value;
        UserServices.getUsers(requestData, this.getUsersCallback);
      }
    }

    getUsersCallback = respData => {

        var state = this.state;
      if (respData.ok) {

        if (respData.data.users.length > 0) {
          let domain = window.location.protocol + "//" + window.location.hostname;
          let port = window.location.port ? ":" + window.location.port + "/" : "/";
          domain = domain + port ;

          var content = this.props.translate("password.emailContent", {changePasswordLink: domain + "changePassword/{notificationId}"}, { renderInnerHtml: false });

          var expiryDate = new Date();

          // add a day
          expiryDate.setDate(expiryDate.getDate() + 1);
          var requestData = {
            emailTo: this.state.email.value,
            emailFrom: this.props.translate("password.emailFrom"),
            subject: this.props.translate("password.emailSubject"),
            content: content,
            expiryDate: expiryDate.toISOString(),
            templateNum: 'SYS-004'
          };
          UserServices.sendNotification(requestData, this.postSendNotificationCallback);
        } else {

          state.email.vs = 'error';
          state.email.errormsg = <Translate id="password.errMsg.invalidEmail" />;
          state.isLoading = false;
          this.setState(state);

        }
  
      } else {
        Prompt.error('Error',respData.error.message,'OK');

        state.isLoading = false;
        this.setState(state);
      }
    }

    postSendNotificationCallback = respData => {
      if (respData.ok) { 
        Prompt.success('Forgot Password',this.props.translate("password.successMsg.forgotPassword"),'OK');
        this.setState({redirectToEvents:true});

      } else {
        Prompt.error('Error',respData.error.message,'OK');
        var state = this.state;
        state.isLoading = false;
        this.setState(state);
      }
    };


    render() {
        
        if (this.state.redirectToEvents === true) {
            return <Redirect to={Constants.CONTEXT_PATH+"login"} />;
        }
    
        return (
          <div className="ForgotPassword"><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/password.css?t="+Constants.VERSION}></link>
          <div className="pageTitle">
          <h1>
            <Translate id="password.pageTitle" />
          </h1>
        </div>
        {this.state.alertClass ? (
          <Alert bsStyle={this.state.alertClass}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        ) : (
          ''
        )}
        <div className="div-form">
          <div className="div-form-inner forgotpassword-form">
            <form onSubmit={this.handleSubmit}>

            <Form.Group controlId="email" size="lg">
                <Form.Label>
                  <Translate id="common.email" />
                </Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  value={this.state.email.value}
                  onChange={this.handleChange}
                  name="email"
                  isInvalid={!!this.state.email.vs}
                />
                <Form.Control.Feedback type="invalid">{this.state.email.errormsg}</Form.Control.Feedback>
              </Form.Group>

            
              <Button block size="lg" type="submit" className="btn-register available" disabled={this.state.isLoading || this.state.email.vs }>
                {this.state.isLoading ? 'Loading' : <Translate id="password.button.update" />  }
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }


}

export default withLocalize(ForgotPassword);