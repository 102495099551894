import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Redirect } from 'react-router-dom';
import Constants from '../../constants';
import { UserServices } from '../../services';
import { Prompt } from '../../utils';
//import zxcvbn from 'zxcvbn';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import { Alert,/* ButtonGroup,*/ Button, Form } from 'react-bootstrap';
import { passwordStrength } from 'check-password-strength';


class ChangePassword extends Component {
    constructor(props) {
      super(props);

      this.state = {
        alertClass: '',
        alertMessage: '',
        notificationId: this.props.match.params.notificationId,
        redirectToEvents: false,
        userId: null,
        password: { value: '', vs: null, errormsg: '' },
        retypePassword: {value: '', vs: null, errormsg: '' },
        isLoading: false

      }
    }
    
    componentDidMount(){
      this.processNotification();
    }

    componentWillUnmount = () => {
      clearTimeout(this.timeout);
    }

    processNotification=()=>{
      //console.log(this.state.notificationId);
      if (this.state.notificationId) {
          var requestData = {
            notificationId: this.state.notificationId
          };
          UserServices.updateNotification(requestData,this.postProcessNotificationCallback);
      }
    }

    postProcessNotificationCallback=(resp)=>{
      //var state = this.state;
      if (resp && resp.ok) {

        var updatedNotification = resp.data.updatedNotification;
        var requestData = '?email=' + updatedNotification.to;
        UserServices.getUsers(requestData, this.getUsersCallback);


      } else {
        
        Prompt.error('Error',resp.error.message,'OK');

      }
    }

    getUsersCallback=(resp)=>{

      if (resp && resp.ok) {

        var state = {

          userId: resp.data.users[0]._id,

        }
        this.setState(state);

      } else {
        Prompt.error('Error',resp.error.message,'OK');
      }

    }



    validatePassword() {
      var state = this.state;
      var score = passwordStrength(this.state.password.value).id;
      if ((this.state.password.value.length > 0) && score >= 2) {
        state.password.vs = null;
        state.password.errormsg = '';
        this.setState(state);
        return true;
      } else {
        state.password.vs = 'error';
        state.password.errormsg = <Translate id="register.errMsg.invalidPassword" />;
        this.setState(state);
        return false;
      }
    }
  
    validateRetypePassword() {
      var state = this.state;
      if (this.state.password.value === this.state.retypePassword.value) {
        state.retypePassword.vs = null;
        state.retypePassword.errormsg = '';
        this.setState(state);
        return true;
      } else {
        state.retypePassword.vs = 'error';
        state.retypePassword.errormsg = <Translate id="password.errMsg.notMatchedPassword" />;
        this.setState(state);
        return false;
      }
    }
    


    postUpdateUsersCallback=(resp)=>{
      if (resp && resp.ok) {
          this.setState({ redirectToEvents: true });

      } else {
          Prompt.error('Error',resp.error.message,'OK');
      }
    }

    handleChange = event => {
      var state = this.state;

      state[event.target.id].value = event.target.value;
      this.setState(state);

      switch (event.target.id) {
        case 'password':
          return this.validatePassword();
  
        case 'retypePassword':
          return this.validateRetypePassword();
  
        default:
          break;
      }
    };

    validateForm() {
      return this.validatePassword() && this.validateRetypePassword();
    }

    handleSubmit = event => {
      event.preventDefault();
      if (this.validateForm()) {
        var state = this.state;
        state.isLoading = true;
        this.setState(state);
        var requestData = {
          userId: this.state.userId,
          password: this.state.password.value
      };
        UserServices.updateUser(requestData, this.postUpdateUsersCallback);
  
      }
    };



    render() {
        
        if (this.state.redirectToEvents === true) {
            return <Redirect to={Constants.CONTEXT_PATH+"login"} />;
        }
    
        return (
          <div className="ChangePassword"><link rel="stylesheet" href={Constants.DOMAIN+"css/password.css?t="+Constants.VERSION}></link>
            <div className="pageTitle">
              <h1>
                <Translate id="password.pageTitle" />
              </h1>
            </div>

            {this.state.alertClass ? (
              <Alert bsStyle={this.state.alertClass}>
                <strong>{this.state.alertMessage}</strong>
              </Alert>
            ) : (
              ''
            )}


            <div className="div-form">
              <div className="div-form-inner changepassword-form">
                <form onSubmit={this.handleSubmit}>

                  <Form.Group controlId="password" size="lg">
                    <Form.Label>
                      <Translate id="common.password" />
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    type="password"
                    value={this.state.password.value}
                    onChange={this.handleChange}
                    name="password"
                    isInvalid={!!this.state.password.vs}
                    />
                      <PasswordStrengthMeter password={this.state.password.value} />
                      <Form.Control.Feedback type="invalid">{this.state.password.errormsg}</Form.Control.Feedback>


                  </Form.Group>
                  <Form.Group controlId="retypePassword" size="lg">
                    <Form.Label>
                    <Translate id="common.retypePassword" />
                    </Form.Label>
                    <Form.Control
                    autoFocus
                    value={this.state.retypePassword.value}
                    onChange={this.handleChange}
                    type="password"
                    name="retypePassword"
                    isInvalid={!!this.state.retypePassword.vs}
                    />
                    <Form.Control.Feedback type="invalid">{this.state.retypePassword.errormsg}</Form.Control.Feedback>
                  </Form.Group>

                  <Button block size="lg" type="submit" className="btn-register available" disabled={this.state.isLoading || this.state.password.vs || this.state.retypePassword.vs }>
                  {this.state.isLoading ? 'Loading' : <Translate id="password.button.update" /> }
                  </Button>
                </form>

              </div>
            </div>
          </div>
        )
  }


}

export default withLocalize(ChangePassword);