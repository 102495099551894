import React, {Fragment} from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
//import Moment from 'react-moment';
import Constants from '../../constants';
import { UserServices, RoomBookingServices } from '../../services';
import { Redirect } from 'react-router';
import { Prompt } from "../../utils";

import TimeRangePicker from '../../components/TimeRangePicker';
const moment = require('moment');

class BookingConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: this.props.match.params.startTime,
      endTime: this.props.match.params.endTime,
      startDate: this.props.match.params.startDate,
      roomId: this.props.match.params.roomId,
      totalPrice: 0,
      rooms: [],
      checked: "",
      redirectToRecord: false,
      searchTimeValue: [],
      searchTime: []  ,
      searchTimeLabel: []
    }
  }

  async componentDidMount(){
    //console.log(this.props.match.params.roomId);
    await this.getSearchTime();
    this.loadDetails();
  }

  getSearchTime(){
    var searchTimeValue = this.props.match.params.searchTimeValue;

    if(searchTimeValue){
        var value = searchTimeValue.split(",");
        //console.log(value);

        const timeRangePicker = new TimeRangePicker();
        var options = timeRangePicker.getOption();
        var timeList = [];
        
        for (let i = 0; i < value.length; i++) {
            var count = parseInt(value[i]);
            //console.log(count);
            value[i] = count;
            timeList[i] = options[count - 1];
            //console.log(options[count - 1]);
        }
        
        value.sort((a, b) => a - b);

        var searchTimeLabel = [];
        var startTime = options.filter((item)=>item.value === value[0]).map(function(item) { return item.label;})[0];
        var startTimeLb = startTime.split(" ");
        
        var endTime = options.filter((item)=>item.value === value[value.length - 1]).map(function(item) { return item.label;})[0];
        var endTimeLb = endTime.split(" ");
        
        searchTimeLabel[0] = startTimeLb[0];
        searchTimeLabel[1] = endTimeLb[1];

        //console.log(timeList);
        this.setState({ searchTime: timeList, searchTimeValue: value, searchTimeLabel: searchTimeLabel});
    }
    
}

  loadDetails = () => {
    //console.log(this.state.roomId);
    let requestData = '/'+this.state.roomId;
    RoomBookingServices.getRoom(requestData, this.loadRoomsCallback);
  }

  loadRoomsCallback = (resp) => {
    if (resp!==null && resp!==undefined && resp.ok) {
      this.setState({rooms: resp.data.rooms});
      this.handlePriceChange();
    }
  }

  handleChange = event => {
    var state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
    //console.log(event.target.value);
    switch (event.target.name) {
      default:
        break;
    }
  };

  handlePriceChange() {
    if(!this.state.endTime || !this.state.startTime){
      this.setState({totalPrice: this.state.rooms[0].price});
      //console.log(this.state.totalPrice);
    } else{
      var endTime = moment(this.state.endTime,"h:mmA");
      var startTime = moment(this.state.startTime,"h:mmA");
      var hours = endTime.diff(startTime,"hours");
      this.setState({totalPrice: this.state.rooms[0].price*hours});
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    var Date = this.state.startDate;

    const timeRangePicker = new TimeRangePicker();
    var options = timeRangePicker.getOption();

    var searchTimeValue = this.state.searchTimeValue;
    //console.log(searchTimeValue);
    var startTime = Date + "T" + options.filter(function(item){ return (item.value === searchTimeValue[0]); }).map(function(item) { return item.startTime; })[0];
    //console.log(startTime);
    var endTime = Date + "T" + options.filter(function(item){ return (item.value === searchTimeValue[searchTimeValue.length - 1]); }).map(function(item) { return item.endTime; })[0];
    //console.log(endTime);

    var hours = searchTimeValue[searchTimeValue.length - 1] - searchTimeValue[0] + 1;
    this.setState({totalPrice: parseInt(this.state.rooms[0].price)*parseInt(hours)},
      function(){
        var requestData = {
          userId: this.props.userData._id,
          user: this.props.userData._id,
          room: this.state.rooms[0]._id,
          startTime: startTime,
          endTime: endTime,
          paymentMethod: "bank",
          totalPrice: this.state.totalPrice,
        }
        console.log(requestData);
        RoomBookingServices.createBooking(requestData, this.bookRecordCallback);
      }
    );
  }

  bookRecordCallback = (resp) => {
    if (resp!==null && resp!==undefined && resp.ok) {
      //console.log(resp.data);
      resp = resp.data.authBooking;
      console.log(resp[0]);
      // send email
      var content = this.props.translate("events.request.successMsg.emailContent", { id: resp[0]._id, startTime: moment(resp[0].startTime, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm"), endTime: moment(resp[0].endTime, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm") }, { renderInnerHtml: false });
      
      // add a day
      var requestData = {
        emailTo: this.props.userData.email,
        emailFrom: this.props.translate("events.request.successMsg.emailFrom"),
        subject: this.props.translate("events.request.successMsg.emailSubject"),
        content: content,
        expiryDate: "",
        roomId: this.state.rooms[0]._id,
        bookingRef: resp[0].bookingRef,
        startTime: moment(resp[0].startTime, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm"),
        endTime: moment(resp[0].endTime, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD HH:mm"),
        templateNum: 'SYS-005',
      };
      UserServices.sendNotification(requestData, this.postSendNotificationCallback);

    } else{
      //console.log(resp);
      Prompt.error(
        this.props.translate('common.sysmsg'),
        this.props.translate('common.errMsg.' + resp.error.message),
        this.props.translate('common.ok'));
    }
  }

  postSendNotificationCallback = respData => {
    if (respData.ok) { 
      this.setState({redirectToRecord:true});
    } else {
      Prompt.error('Error',respData.error.message,'OK');
      var state = this.state;
      state.isLoading = false;
      this.setState(state);
    }
  };

  render() {
    if (this.state.redirectToRecord === true) {
      //console.log("redirect to "+from);
      //return <Redirect to={Constants.CONTEXT_PATH + 'events'} />;
      return <Redirect to={Constants.CONTEXT_PATH+"bookingHistory"} />;
    }

    return (
      <Fragment>
        <link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/events.css?t="+Constants.VERSION}></link>
        <Container>
        <div className="pageTitle">
          <Row>
            <h1>
                <Translate id="common.appName" />
            </h1>
          </Row>
        </div>

        <Row xs={1} sm={1} md={1} lg={1}>
        {this.state.rooms.map(item => (
        <Card className="card">
          <Card.Body className="p-2">
            <Card.Title><Translate id="events.request.room.bookConfirmation"/>:{item.name}</Card.Title>
            <Card.Text className="mb-0">
              <Translate id="events.request.room.distinct"/>:{item.district}
            </Card.Text>
            <Card.Text>
              <Translate id="events.request.room.address"/>:{item.address}
            </Card.Text>
          </Card.Body>
          <div style={{ 
            height: '30vh',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url(data:`+item.upload.mimetype+';base64,'+item.picture+`)` 
          }}>
          </div>
          <Card.Body>
            <Card.Text>
              <Translate id="events.request.room.date"/>:{this.state.startDate}
            </Card.Text>
            <Card.Text>
              <Row>
                <Col><Translate id="events.starttime"/>:{this.state.searchTimeLabel[0]}</Col>
                <Col><Translate id="events.endtime"/>:{this.state.searchTimeLabel[1]}</Col>
              </Row>
            </Card.Text>
            {/* <Card.Text>
              <Translate id="events.request.room.paymentChoice"/>:
            </Card.Text>
            <Form>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Row>
                    <Col xs={3} sm={3} md={3} lg={3}>
                      <Form.Check
                        inline
                        type={type}
                        name="checked"
                        value="paypal"
                        onChange={this.handleChange}
                        id={`inline-${type}-1`}
                      />
                      <Figure className="payment-method-img">
                        <Figure.Image
                          alt="Paypal"
                          src={Constants.DOMAIN + "img/icons/logo_Paypal.png"}
                        />
                      </Figure>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3}>
                      <Form.Check
                        inline
                        type={type}
                        name="checked"
                        value="payme"
                        onChange={this.handleChange}
                        id={`inline-${type}-2`}
                      />
                      <Figure className="payment-method-img">
                        <Figure.Image
                          alt="PayMe"
                          src={Constants.DOMAIN + "img/icons/logo-payme.png"}
                        />
                      </Figure>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3}>
                      <Form.Check
                        inline
                        type={type}
                        name="checked"
                        value="wechat"
                        onChange={this.handleChange}
                        id={`inline-${type}-3`}
                      />
                      <Figure className="payment-method-img">
                        <Figure.Image
                          alt="WeChat"
                          src={Constants.DOMAIN + "img/icons/logo_WeChat_Pay.png"}
                        />
                      </Figure>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3}>
                      <Form.Check
                        inline
                        type={type}
                        name="checked"
                        value="alipay"
                        onChange={this.handleChange}
                        id={`inline-${type}-3`}
                      />
                      <Figure className="payment-method-img">
                        <Figure.Image
                          alt="AliPay"
                          src={Constants.DOMAIN + "img/icons/logo_Alipay.png"}
                        />
                      </Figure>
                    </Col>
                  </Row>
                </div>
              ))}
            </Form> */}
            <Card.Text>
              <Translate id="events.request.room.paymentMethod"/>:
            </Card.Text>

            <Card className="p-2 w-50 m-5">
              <Card.Title>
                  <Translate id="events.request.room.paymentTransfer"/>
              </Card.Title>
              <Card.Text>
                  {/* ${this.state.totalPrice}<br/> */}
                  <b><Translate id="events.request.room.bankInfo"/></b>
              </Card.Text>
            </Card>

            <Row>
              <Col><Button variant="secondary" size="lg" block onClick={()=>{this.props.history.goBack()}}><Translate id="events.request.room.prevStep"/></Button></Col>
              <Col><Button variant="success" size="lg" block onClick={this.handleSubmit}><Translate id="events.request.room.ntStep"/></Button></Col>
            </Row>
          </Card.Body>
        </Card>
        ))}
        </Row>
        <Row className="mb-5"></Row>
        <Row className="mb-5"></Row>
      </Container></Fragment>
    );
  }
};

export default withLocalize(BookingConfirmation);