import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Alert,/* ButtonGroup,*/ Col, Row, Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import { UserServices } from '../../services';
import { Prompt } from '../../utils';
import Constants from '../../constants';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
//import zxcvbn from 'zxcvbn';
import emailValidator from 'email-validator';
import FacebookLogin from 'react-facebook-login';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { passwordStrength } from 'check-password-strength';
//import Pdf from '../../Documents/TermsAndConditions.pdf';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: props.userData,
      alertClass: '',
      alertMessage: '',
      redirectToReferrer: props.isLoggedIn,
      redirectToEvents: false,
      nickname: { value: props.userData?props.userData.displayName:'', vs: null, errormsg: ''},
      email: { value: '', vs: null, errormsg: '' },
      phoneCountry: { value: '+852' },
      mobile: { value: '', vs: null, errormsg: '' },
      password: { value: '', vs: null, errormsg: '' },
      retypePassword: {value: '', vs: null, errormsg: '' },
      verified: true,
      recaptchaResponse: '',
      role: 'Temp',
      transferAccount: {value: false, vs: null, errormsg: '' },
      disableTnC: true,
      isLoading: false,
      termsAndConditions: '/termCondition',
      fbLogin: false
    };
  }

  async componentDidMount() {
    if (this.props.isLoggedIn) {
      this.setState({redirectToEvents: true});
    }

    /*try {
      const token = JSON.parse(localStorage.getItem(Constants.SESSION_KEY_USER_DATA)).token;
      const authUrl = `${Commons.getApiUrlBase()}${myConfig.apiAuthService}sessions`;

      const result = await axios({
        method: 'get',
        url: authUrl,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => res.data);

      if (result.ok) {
        let redirectPath = result.data.isFinance
          ? `${myConfig.contextRoot}refundCase`
          : result.data.hasCouponDRole
          ? `${myConfig.contextRoot}couponD`
          : result.data.hasPrepaymentRole
          ? `${myConfig.contextRoot}prepaid`
          : `${myConfig.contextRoot}dashboard`;
        this.props.history.push(redirectPath);
      }
    } catch (e) {
      // error verifying login, no need to redirect to dashboard
    }*/
  }


  labelWithLink() {
    return (
    <span> {this.props.translate("register.termsAndCondition.readAndAgreed")} {this.props.translate("register.termsAndCondition.leftParenthesis")}
      <a
        // href={Pdf}
        href={this.state.termsAndConditions}
        target="_blank"
        rel="noopener noreferrer"
        onClick={this.handleClick}
      >
        {this.props.translate("register.termsAndCondition.title")}
      </a>
      {this.props.translate("register.termsAndCondition.rightParenthesis")}
    </span>
    );
  }


  validateEmail() {
    var state = this.state;
    if ((this.state.email.value.length <= 0) || !emailValidator.validate(this.state.email.value)) {
      state.email.vs = 'error';
      state.email.errormsg = <Translate id="register.errMsg.invalidEmail" />;
      this.setState(state);
      return false;
    } else {
      state.email.vs = null;
      state.email.errormsg = '';
      this.setState(state);
      return true;
    }
  }

  validatePassword() {
    var state = this.state;
    var score = passwordStrength(this.state.password.value).id;
    if ((this.state.password.value.length > 0) && score >= 2) {
      state.password.vs = null;
      state.password.errormsg = '';
      this.setState(state);
      return true;
    } else {
      state.password.vs = 'error';
      state.password.errormsg = <Translate id="register.errMsg.invalidPassword" />;
      this.setState(state);
      return false;
    }
  }

  validateRetypePassword() {
    var state = this.state;
    if (this.state.password.value === this.state.retypePassword.value) {
      state.retypePassword.vs = null;
      state.retypePassword.errormsg = '';
      this.setState(state);
      return true;
    } else {
      state.retypePassword.vs = 'error';
      state.retypePassword.errormsg = <Translate id="register.errMsg.notMatchedPassword" />;
      this.setState(state);
      return false;
    }
  }
  
  validateNickname() {
    var state = this.state;
    if (this.state.nickname.value.length > 0) {
      state.nickname.vs = null;
      state.nickname.errormsg = '';
      this.setState(state);
      return true;
    } else {
      state.nickname.vs = 'error';
      state.nickname.errormsg = <Translate id="register.errMsg.noNickname" />;
      this.setState(state);
      return false;
    }
  }


  validateTransferAccount() {
    var state = this.state;
    //console.log(state.transferAccount.value);
    // state.transferAccount = state.transferAccount.value;
    //console.log(state.transferAccount.value);
    this.setState(state);
    //console.log(this.state.transferAccount.value);
    return true;
  }

  validateForm() {
    return this.validateEmail() && this.validatePassword() && this.validateRetypePassword() && this.validateNickname();
  }

  handleChange = event => {
    /*this.setState({
      [event.target.id]: event.target.value
    });*/
    var state = this.state;
    //console.log(event.target.id);
    //console.log(event.target.value);
    //console.log(event.target.checked);
    //console.log(this.state.transferAccount.value);
    state[event.target.id].value = event.target.value;
    this.setState(state);
    //console.log(this.state.transferAccount.value);
    switch (event.target.id) {
      case 'email':
        return this.validateEmail();

      case 'password':
        return this.validatePassword();

      case 'retypePassword':
        return this.validateRetypePassword();
    
      case 'nickname':
        return this.validateNickname();

      case 'transferAccount':
        state[event.target.id].value = event.target.checked;
        return this.validateTransferAccount();

      default:
        break;
    }
  };

  handlePhoneCountryChange = (country) => {
    var state = this.state;
    state['phoneCountry'].value = country;
    this.setState(state);
  }

  handleClick = () => {
    var state = this.state;
    state.disableTnC = false;
    this.setState(state);
    return true;

  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      var state = this.state;
      state.isLoading = true;
      this.setState(state);

      var requestData = '?name=Normal';

      UserServices.getUserRoles(requestData, this.getUserRolesCallback);

    }
  };

  /*handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      var state = this.state;
      state.isLoading = true;
      this.setState(state);
      var requestData = {
        recaptchaResponse: this.state.recaptchaResponse
      };
      AuthServices.authrecaptcha(requestData, this.postAuthRecaptchaCallback);

    }
  };

  postAuthRecaptchaCallback = resp =>{

    if (resp && resp.ok) {


      var requestData = '?name=Temp';

      UserServices.getUserRoles(requestData, this.getUserRolesCallback);


    } else {
      Prompt.error('Error',resp.error.message,'OK');
      var state = this.state;
      state.isLoading = false;
      this.setState(state);
    }

  }*/
  

  getUserRolesCallback = respData => {

  if (respData.ok) {
      var requestData = {
        email: this.state.email.value,
        password: this.state.password.value,
        displayName: this.state.nickname.value,
        name: this.state.email.value,
        mobile: this.state.phoneCountry.value + this.state.mobile.value,
        //recaptchaResponse: this.state.recaptchaResponse,
        roles: respData.data.userRoles[0]._id
      };

      if (!this.state.transferAccount.value){
        UserServices.register(requestData, this.postRegisterCallback);        
      } else {
        requestData.userId = this.state.userData._id;
        UserServices.updateUser(requestData, this.postRegisterCallback);
      }
  
    } else {

        Prompt.error('Error',respData.error.message,'OK');
        var state = this.state;
        state.isLoading = false;
        this.setState(state);
    }
  };

  postRegisterCallback = respData => {

    if (respData.ok) {
      localStorage.setItem(Constants.SESSION_KEY_USER_DATA, JSON.stringify(respData.data.authuser));
      this.props.userHasLoggedIn(true);
      this.props.setUser(respData.data.authuser);

      var content = this.props.translate("register.activate.emailContent", { activationLink: Constants.DOMAIN + "activate/{notificationId}"}, { renderInnerHtml: false });
      var expiryDate = new Date();

      // add a day
      expiryDate.setDate(expiryDate.getDate() + 1);

      var requestData = {
        emailTo: this.state.email.value,
        emailFrom: this.props.translate("register.activate.emailFrom"),
        subject: this.props.translate("register.activate.emailSubject"),
        content: content,
        expiryDate: expiryDate.toISOString(),
        templateNum: 'SYS-003'
      };
      UserServices.sendNotification(requestData, this.postSendNotificationCallback);

    } else {
      //this.setState({alertClass:"danger", alertMessage: respData.error.message});
      //this.props.showPromptMessage(respData.error.message);
      //localStorage.getItem(Constants.ACTIVE_LANG_CODE)
      Prompt.error('Error',respData.error.message,'OK');
      var state = this.state;
      state.isLoading = false;
      this.setState(state);
    }
  };

  postSendNotificationCallback = respData => {
    if (respData.ok) { 
      Prompt.success('Success',this.props.translate("register.successMsg.checkEmail"),'OK');
      setTimeout(
        () => {
          this.setState({redirectToEvents:true});
        }, 
        3000
      )

    } else {
      Prompt.error('Error',respData.error.message,'OK');
      var state = this.state;
      state.isLoading = false;
      this.setState(state);
    }
  };


  render() {
    if (this.state.redirectToEvents === true) {
      //console.log("redirect to "+from);
      return <Redirect to={Constants.CONTEXT_PATH + 'events'} />;
    }

    return (

      <div className="Register"><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/register.css?t="+Constants.VERSION}></link>
        <div className="pageTitle">
          <h1>
            <Translate id="register.pageTitle" />
          </h1>
        </div>

        {this.state.alertClass ? (
          <Alert bsStyle={this.state.alertClass}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        ) : (
          ''
        )}

        <div className="div-form">
          <div className="div-form-inner register-form">
            <form onSubmit={this.handleSubmit}>
            
            <Form.Group controlId="nickname" size="lg">
                <Form.Control
                  autoFocus
                  type="nickname"
                  onChange={this.handleChange}
                  value={this.state.nickname.value}
                  name="nickname"
                  isInvalid={!!this.state.nickname.vs}
                  placeholder={this.props.translate("common.nickname")}
                />
                <Form.Control.Feedback type="invalid">{this.state.nickname.errormsg}</Form.Control.Feedback>
              
              </Form.Group>

              <Form.Group controlId="password" size="lg">
                <Form.Control
                  autoFocus
                  type="password"
                  value={this.state.password.value}
                  onChange={this.handleChange}
                  name="password"
                  isInvalid={!!this.state.password.vs}
                  placeholder={this.props.translate("common.password")}
                /><span id="pwd-info" data-tip="React-tooltip"><FontAwesomeIcon icon={faInfoCircle} /></span>

<ReactTooltip place="right" type="dark" effect="float"><span>{this.props.translate("password.requirements")}</span></ReactTooltip>
                <PasswordStrengthMeter password={this.state.password.value} />
                
                <Form.Control.Feedback type="invalid">{this.state.password.errormsg}</Form.Control.Feedback>


              </Form.Group>
              <Form.Group controlId="retypePassword" size="lg">
                <Form.Control
                  autoFocus
                  value={this.state.retypePassword.value}
                  onChange={this.handleChange}
                  type="password"
                  name="retypePassword"
                  isInvalid={!!this.state.retypePassword.vs}
                  placeholder={this.props.translate("common.retypePassword")}
                />
                <Form.Control.Feedback type="invalid">{this.state.retypePassword.errormsg}</Form.Control.Feedback>
              </Form.Group>

              
              <Form.Group controlId="email" size="lg">
                <Form.Control
                  autoFocus
                  type="email"
                  value={this.state.email.value}
                  onChange={this.handleChange}
                  name="email"
                  isInvalid={!!this.state.email.vs}
                  placeholder={this.props.translate("common.email")}
                />
                <Form.Control.Feedback type="invalid">{this.state.email.errormsg}</Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col xs={5} sm={4} md={4} lg={4} className="pr-2">
                  <PhoneInput
                    international
                    defaultCountry="HK"
                    value={this.state.phoneCountry.value}
                    onChange={this.handlePhoneCountryChange}/>
                </Col>
                <Col xs={7} sm={8} md={8} lg={8}>
                  <Form.Group controlId="mobile" size="lg">
                    <Form.Control
                          autoFocus
                          type="mobile"
                          value={this.state.mobile.value}
                          onChange={this.handleChange}
                          name="mobile"
                          isInvalid={!!this.state.mobile.vs}
                          placeholder={this.props.translate("common.phone")}
                        />
                      <Form.Control.Feedback type="invalid">{this.state.mobile.errormsg}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group align="left">

                  <Form.Check
                    required
                    feedback="You must agree before submitting."
                    label={this.labelWithLink()}
                    disabled={this.state.disableTnC}
                  >
                  </Form.Check>

              </Form.Group>

              <Button block size="lg" type="submit" className="btn-register available app-style-btn" disabled={this.state.isLoading || this.state.email.vs  || this.state.password.vs || this.state.retypePassword.vs || this.state.nickname.vs}>
              {/* <Button block size="lg" type="submit" className="btn-register available" disabled={this.state.isLoading || !this.state.verified || this.state.email.vs  || this.state.password.vs || this.state.retypePassword.vs || this.state.nickname.vs}></Button> */}
                {this.state.isLoading ? 'Loading' : <Translate id="register.button.register" />  }
              </Button>
            </form>
            <Row className="my-2"><Col>{this.props.translate("common.orSeparator")}</Col></Row>
            <Row>
              <FacebookLogin 
                        appId={Constants.APP_ID.FB}
                        onClick={()=>this.setState({fbLogin:true})}
                        callback={this.facebookLoginCallback}
                        fields="name,email,picture"
                        icon="fa-facebook"
                        textButton={this.props.translate("login.button.facebook")}
                      />
              </Row>

              <Row className="mb-5"></Row>
              <Row className="mb-5"></Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(Register);
