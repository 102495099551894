import React from "react";
import { Switch } from "react-router-dom";
import Constants from '../constants';
import PrivateRoute from "./PrivateRoute";
import AppliedRoute from "./AppliedRoute";
import { Room, Accounts,Booking, Others } from "../containers";

export default ({ childProps }) =>
  <Switch>
      <AppliedRoute path={Constants.CONTEXT_PATH+"activate/:notificationId"} component={Accounts.Activate} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"changePassword/:notificationId"} component={Accounts.ChangePassword} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"events"} component={Room.RoomList} props={childProps} />
      <PrivateRoute path={Constants.CONTEXT_PATH+"profile"} exact component={Accounts.Profile} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"login"} component={Accounts.Login} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"register"} component={Accounts.Register} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"forgotPassword"} component={Accounts.ForgotPassword} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"roomDetails/:roomId/:startDate/:searchTimeValue"} component={Room.RoomDetails} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"roomDetails/:roomId"} component={Room.RoomDetails} props={childProps} />
      <PrivateRoute path={Constants.CONTEXT_PATH+"bookingConfirmation/:roomId/:searchTimeValue/:startDate"} component={Booking.BookingConfirmation} props={childProps} />
      <PrivateRoute path={Constants.CONTEXT_PATH+"bookingHistory"} exact component={Booking.BookingHistory} props={childProps} />
      <PrivateRoute path={Constants.CONTEXT_PATH+"managerBookingHistory"} exact component={Booking.ManagerBookingHistory} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"venueBookingCondition"} component={Others.VenueBooking} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"termCondition"} component={Others.TermsConditions} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH+"aboutUs"} component={Others.AboutUs} props={childProps} />
      <AppliedRoute path={Constants.CONTEXT_PATH} component={Room.RoomList} props={childProps} />

  </Switch>;