import Constants from '../constants';
import { Commons } from './Commons';

const serviceUrl = Constants.API.ROOM_BOOKING_SERVICES;

export {
  getRoom,
  getAvailableRoom,
  createBooking,
  getBooking,
};


function getRoom(requestData, callback) {
  var callingService = serviceUrl + 'rooms';
  if (requestData) {
      callingService += requestData;
  }

  //console.log('calling ' + callingService);
  Commons.get(callingService, callback);
}

function getAvailableRoom(requestData, callback) {
  var callingService = serviceUrl + 'availableRoom';
  Commons.post(callingService, requestData, callback);
}

function createBooking(requestData, callback) {
  var callingService = serviceUrl + 'createBooking';
  //console.log('calling '+callingService);
  Commons.post(callingService, requestData, callback);
}

function getBooking(requestData, callback) {
  var callingService = serviceUrl + 'getBooking';
  //console.log('calling '+callingService);
  Commons.post(callingService, requestData, callback);
}