import React, { Fragment } from "react";
import { withLocalize, Translate } from "react-localize-redux";
import {  Container,  Row,  Col, Button, Form, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCalendarAlt  } from "@fortawesome/free-solid-svg-icons";
import Constants from "../../constants";
import { /*UserServices,*/ RoomBookingServices } from "../../services";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import { Prompt } from "../../utils";
import TimeRangePicker from '../../components/TimeRangePicker';
//import DatePicker from '../../components/DatePicker';

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

const moment = require("moment");
const timeRangePicker = new TimeRangePicker();

class RoomList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.userId === undefined ? null : this.props.userId,
      userName: this.props.userName === undefined ? null : this.props.userName,
      rooms: [],
      displayRooms: [],
      disableRoomsId: [],
      searchKey: { value: "", vs: null, errormsg: "" },
      /* searchTime: { value: "", vs: null, errormsg: "" }, */
      searchTimeValue: [],
      searchTime: [],
      startDate: { value: new Date() },
      dateSelected: false,
      isOpen: false,
      startTimeHour: { value: "", vs: null },
      startTimeMin: { value: "", vs: null },
      endTimeHour: { value: "", vs: null },
      endTimeMin: { value: "", vs: null },
      options: timeRangePicker.getOption()
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  autoEndTimeByStartTime(startTimeHour, startTimeMin) {
    var state = this.state;

    var time = this.forceTime(startTimeHour, startTimeMin);
    startTimeHour = time[0];
    startTimeMin = time[1];

    // add one more hour
    var endTimeHour = moment(startTimeHour + startTimeMin, "HHmm")
      .add(1, "H")
      .format("HH");
    var endTimeMin = moment(startTimeHour + startTimeMin, "HHmm")
      .add(1, "H")
      .format("mm");

    state["startTimeHour"].value = startTimeHour;
    state["startTimeMin"].value = startTimeMin;
    state["endTimeHour"].value = endTimeHour;
    state["endTimeMin"].value = endTimeMin;

    this.setState(state);
  }

  autoEndTime(startTimeHour, startTimeMin, endTimeHour, endTimeMin) {
    var state = this.state;

    if (startTimeHour === endTimeHour && startTimeMin === endTimeMin) {
      endTimeHour = moment(endTimeHour + endTimeMin, "HHmm")
        .add(1, "H")
        .format("HH");
    } else if (startTimeMin === "00" && endTimeMin !== "00") {
      endTimeHour = moment(endTimeHour + endTimeMin, "HHmm")
        .add(1, "H")
        .format("HH");
      endTimeMin = "00";
    } else if (startTimeMin === "30" && endTimeMin === "00") {
      endTimeMin = "30";
    } else if (
      startTimeMin === "30" &&
      endTimeMin !== "30" &&
      endTimeMin !== "00"
    ) {
      endTimeHour = moment(endTimeHour + endTimeMin, "HHmm")
        .add(1, "H")
        .format("HH");
      endTimeMin = "30";
    }
    state["endTimeHour"].value = endTimeHour;
    state["endTimeMin"].value = endTimeMin;

    this.setState(state);
  }

  forceTime(hour, minute) {
    if (minute < 15) {
      minute = "00";
    }
    if (minute >= 15 && minute <= 30) {
      minute = "30";
    }
    if (minute > 30 && minute < 45) {
      minute = "30";
    }
    if (minute >= 45 && minute < 60) {
      hour = moment(hour + minute, "HHmm")
        .add(1, "H")
        .format("HH");
      minute = "00";
    }
    return [hour, minute];
  }

  handleDateChange = (date) => {
    //console.log(date);
    var state = this.state;
    state["startDate"].value = date;
    this.setState(state);
  };

  validateNumber(input) {
    if (isNaN(input)) {
      Prompt.error(
        "Error",
        this.props.translate("common.errMsg.INVALID_TIME"),
        "OK"
      );
      return false;
    }
    return true;
  }

  validateStartTime(startTimeHour, startTimeMin) {
    if (startTimeHour < 10 || startTimeHour > 19) {
      Prompt.error(
        "Error",
        this.props.translate("common.errMsg.INVALID_TIME_02"),
        "OK"
      );
      return false;
    }
    if (startTimeMin < 0 || startTimeMin > 59) {
      Prompt.error(
        "Error",
        this.props.translate("common.errMsg.INVALID_TIME_02"),
        "OK"
      );
      return false;
    }
    return true;
  }

  validateEndTime(startTimeHour, endTimeHour, endTimeMin) {
    if (endTimeHour > 20 || (endTimeHour === 20 && endTimeMin > 0)) {
      Prompt.error(
        "Error",
        this.props.translate("common.errMsg.INVALID_TIME_02"),
        "OK"
      );
      return false;
    }
    if (endTimeHour < startTimeHour || endTimeHour < 10) {
      Prompt.error(
        "Error",
        this.props.translate("common.errMsg.INVALID_TIME_RANGE"),
        "OK"
      );
      return false;
    }
    return true;
  }

  handleChange = (event) => {
    //console.log(this.state.searchTime);

    var state = this.state;
    state[event.target.id].value = event.target.value;
    this.setState(state);

    switch (event.target.id) {
      case "searchKey":
        this.loadRooms();
        break;
      case "startTimeHour":
      case "startTimeMin":
        var startTimeHour = this.state.startTimeHour.value;
        var startTimeMin = this.state.startTimeMin.value;

        this.validateNumber(event.target.value);

        if (startTimeHour && startTimeMin) {
          switch (startTimeMin) {
            /* case "00":
            case "30": */
            case "0":
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
              break;
            default:
              if (!(startTimeHour === "0" || startTimeHour === "1")) {
                this.autoEndTimeByStartTime(startTimeHour, startTimeMin);
              }
          }
        }
        break;
      case "endTimeHour":
      case "endTimeMin":
        startTimeHour = this.state.startTimeHour.value;
        startTimeMin = this.state.startTimeMin.value;
        var endTimeHour = this.state.endTimeHour.value;
        var endTimeMin = this.state.endTimeMin.value;

        this.validateNumber(event.target.value);
        //console.log(endTimeMin);
        switch (endTimeMin) {
          /* case "00":
          case "30": */
          case "":
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
            break;
          default:
            if (
              startTimeMin !== endTimeMin ||
              (startTimeHour === endTimeHour && startTimeMin === endTimeMin)
            ) {
              this.autoEndTime(
                startTimeHour,
                startTimeMin,
                endTimeHour,
                endTimeMin
              );
            }
        }

        break;
      default:
        break;
    }
  };

  validateSearchTime() {
    var startTimeHour = this.state.startTimeHour.value;
    var startTimeMin = this.state.startTimeMin.value;
    var endTimeHour = this.state.endTimeHour.value;
    var endTimeMin = this.state.endTimeMin.value;

    return (
      this.validateStartTime(startTimeHour, startTimeMin) &&
      this.validateEndTime(startTimeHour, endTimeHour, endTimeMin)
    );
  }

  checkAvailable(arr){
    var error = arr.filter((num, i) => (num - arr[i - 1] !== 1) );
    //console.log(error);
    
    if(error.length > 1){
        return false;
    } else{
        return true;
    }
  }

  handleSubmit = (event) => {
    this.closeModal();
    event.preventDefault();

    //console.log(this.state.searchTime);

    var Date = moment(this.state.startDate.value).format("YYYY-MM-DD");
    var value = this.state.searchTime.map(function(item){ return item.value; });

    value.sort((a, b) => a - b);
    //console.log(value);

    const timeRangePicker = new TimeRangePicker();
    var options = timeRangePicker.getOption();

    var startTime = Date + "T" + options.filter(function(item){ return (item.value === value[0]); }).map(function(item) { return item.startTime; })[0];
    //console.log(startTime);
    var endTime = Date + "T" + options.filter(function(item){ return (item.value === value[value.length - 1]); }).map(function(item) { return item.endTime; })[0];
    //console.log(endTime);

    if(this.checkAvailable(value)){
      var requestData = {
        startTime: startTime,
        endTime: endTime
      };
      //console.log(requestData);
      RoomBookingServices.getAvailableRoom(requestData, this.loadDisableRoomsCallback);
      this.setState({ dateSelected: true, searchTimeValue: value });
    } else{
        Prompt.error(
            this.props.translate('common.sysmsg'),
            this.props.translate('common.errMsg.INVALID_TIME_SLOT'),
            this.props.translate('common.ok'));
    }    

    /* if (this.validateSearchTime()) {
      var Date = moment(this.state.startDate.value).format("YYYY-MM-DD");
      var sTime =
        this.state.startTimeHour.value + this.state.startTimeMin.value;
      var eTime = this.state.endTimeHour.value + this.state.endTimeMin.value;

      var startTime =
        moment(Date + sTime, "YYYY-MM-DDHHmm").format("YYYY-MM-DDTHH:mm:ss") +
        "Z";
      var endTime =
        moment(Date + eTime, "YYYY-MM-DDHHmm").format("YYYY-MM-DDTHH:mm:ss") +
        "Z";

      var requestData = {
        startTime: startTime,
        endTime: endTime,
      };
      //console.log(requestData);
      RoomBookingServices.getAvailableRoom(requestData, this.loadDisableRoomsCallback);
      
      this.setState({ dateSelected: true });
    } */
  };

  setSearchTime = (e) => {
    this.setState({ searchTime: e });
  };

  onFormSubmit(e) {
    e.preventDefault();
    //console.log(this.state.startDate);
  }

  componentDidMount() {
    this.loadRooms();
  }

  loadRooms = () => {
    let requestData = "";
    RoomBookingServices.getRoom(requestData, this.loadRoomsCallback);
  };

  fetchEvents = () => {};

  loadRoomsCallback = (resp) => {
    var regex = new RegExp("(" + this.state.searchKey.value + ")", "gi");

    if (resp !== null && resp !== undefined && resp.ok) {
      this.setState({
        rooms:
          this.state.searchKey.value === "" &&
          this.state.disableRoomsId.length === 0
            ? resp.data.rooms
            : resp.data.rooms.filter(
                (e) =>
                  e.district.match(regex) &&
                  !this.state.disableRoomsId.includes(e._id)
              ),
      });
    } else {
      if (resp && resp.error) {
        Prompt.error(
          this.props.translate("common.sysmsg"),
          this.props.translate("common.errMsg." + resp.error.message),
          this.props.translate("common.ok")
        );
      }
    }
  };

  loadDisableRoomsCallback = (resp) => {
    //console.log(resp);
    if (resp !== null && resp !== undefined && resp.ok) {
      this.setState({ disableRoomsId: resp.data.result });
      
      var state = this.state;
      state["searchTime"].value =
        this.state.startTimeHour.value +
        ":" +
        this.state.startTimeMin.value +
        "/" +
        this.state.endTimeHour.value +
        ":" +
        this.state.endTimeMin.value +
        "/" +
        moment(this.state.startDate.value).format("YYYY-MM-DD");
        this.setState(state);

        this.loadRooms();
    } else {
      Prompt.error(
        this.props.translate("common.sysmsg"),
        this.props.translate("common.errMsg." + resp.error.message),
        this.props.translate("common.ok")
      );
    }
  };

  render() {

    return (
      <Fragment>
        <link
          rel="stylesheet"
          href={
            Constants.CONTEXT_PATH + "css/events.css?t=" + Constants.VERSION
          }
        ></link>
        <Container>
          <div className="pageTitle">
            <Row>
              <h1>
                <Translate id="common.appName" />
              </h1>
            </Row>
          </div>
          <Row>
            <Col xs={10} sm={11} md={11} lg={11}>
              <div className="div-form">
                <div className="div-form-inner search-form">
                  <form>
                    <Form.Group controlId="searchKey" size="lg">
                      <Form.Control
                        autoFocus
                        type="searchKey"
                        onChange={this.handleChange}
                        value={this.state.searchKey.value}
                        name="searchKey"
                        isInvalid={!!this.state.searchKey.vs}
                        placeholder={this.props.translate(
                          "events.request.book.type_search"
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.searchKey.errormsg}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </form>
                </div>
              </div>
            </Col>

            <Col xs={2} sm={1} md={1} lg={1}>
              <Button
                variant="primary"
                onClick={this.openModal}
                className="available"
              >
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                <div className={this.state.dateSelected === true ? "triangle d-block" : "triangle d-none"}></div>
              </Button>
              
              <Modal centered show={this.state.isOpen} onHide={this.closeModal}>
                <Modal.Header closeButton className="py-2"></Modal.Header>
                <Modal.Body className="py-2">
                  <Row className="my-2">
                    <DatePicker
                      inline
                      value={this.state.startDate.value ? this.state.startDate.value : new Date()}
                      selected={this.state.startDate.value ? this.state.startDate.value : new Date()}
                      onChange={this.handleDateChange}
                      id="startDate"
                      dateFormat="MM/dd/yyyy"
                      minDate={new Date()}
                      maxDate={addDays(new Date(), 7)}
                    />
                    {/* <DatePicker /> */}
                  </Row>

                  <TimeRangePicker placeholderButtonLabel={this.props.translate("events.request.book.choose_time_search")} option={this.state.options} value={this.state.searchTime} onChange={(e) => this.setSearchTime(e) } />
                  {/* <Row className="my-2 px-4">
                    <Translate id="events.starttime" />
                  </Row>
                  <Row className="my-2 px-4">
                    <Col>
                      <Form.Group controlId="startTimeHour">
                        <Form.Control
                          autoFocus
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.startTimeHour.value}
                          name="startTimeHour"
                          isInvalid={!!this.state.startTimeHour.vs}
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      className="p-0 text-center">
                      :
                    </Col>
                    <Col>
                      <Form.Group controlId="startTimeMin">
                        <Form.Control
                          autoFocus
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.startTimeMin.value}
                          name="startTimeMin"
                          isInvalid={!!this.state.startTimeMin.vs}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2 px-4">
                    <Translate id="events.endtime" />
                  </Row>
                  <Row className="my-2 px-4">
                    <Col>
                      <Form.Group controlId="endTimeHour">
                        <Form.Control
                          autoFocus
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.endTimeHour.value}
                          name="endTimeHour"
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      className="p-0 text-center"
                    >
                      :
                    </Col>
                    <Col>
                      <Form.Group controlId="endTimeMin">
                        <Form.Control
                          autoFocus
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.endTimeMin.value}
                          name="endTimeMin"
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}
                </Modal.Body>
                <Modal.Footer className="py-2">
                  <Button
                    variant="primary"
                    type="submit"
                    className="available"
                    onClick={this.handleSubmit}
                    disabled={
                      !this.state.searchTime
                      /* !this.state.startTimeHour.value ||
                      !this.state.startTimeMin.value ||
                      !this.state.endTimeHour.value ||
                      !this.state.endTimeMin.value */
                    }
                    >
                    <Translate id="events.request.book.pick" />
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>

          <Row xs={1} sm={3} md={3} lg={3}>
            {this.state.rooms.map((item) => (
              <Card className="mb-2 mx-1 p-0">
                <div className="d-none">{item._id}</div>
                <Button
                  variant="link"
                  type="submit"
                  className="text-decoration-none"
                  onClick={() => {
                    this.props.history.push(
                      Constants.CONTEXT_PATH +
                        "roomDetails/" +
                        item._id +
                        "/" +
                        this.state.startDate.value +
                        "/" +
                        this.state.searchTimeValue
                    );
                  }}
                >
                  <Card.Body>
                    <Card.Title>
                      <Row>
                        <Col xs={5} sm={4} md={4} lg={4} className="text-left">
                          {item.district}
                        </Col>
                        <Col
                          xs={7}
                          sm={8}
                          md={8}
                          lg={8}
                          className="text-right font-size-xs"
                        >
                          {item.address}
                        </Col>
                      </Row>
                    </Card.Title>
                    <Card.Img variant="top" src={'data:'+item.upload.mimetype+';base64,'+item.picture} />
                    <Card.Text className="mt-2 text-left">
                      {item.name}
                    </Card.Text>
                  </Card.Body>
                </Button>
              </Card>
            ))}
          </Row>

          <Row className="mb-5"></Row>
          <Row className="mb-5"></Row>

        </Container>
      </Fragment>
    );
  }
}

export default withLocalize(RoomList);
