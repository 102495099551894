import React, { Component } from 'react';
import { withLocalize, /*Translate*/ } from 'react-localize-redux';

class VenueBooking extends Component {
    /*constructor(props) {
      super(props);
    }*/

    render() {
        return (
            <div className='text-left'>
                <h1>場地使用守則</h1>
                <b>為確保使用場地者愛惜場地物品及保持場地清潔，租用場地使用者需於進場時繳付場地租用按金$500(攜帶酒精飲品進場按金額外加$300)。如有任何損毀，髒亂如打翻飲料、嘔吐物、食物殘渣四散等，我們有權扣除全數按金作服務費。歡迎自攜飲品及食物，並於完場前還原場地整理乾淨。請貴客於完場前預留15分鐘清理場地，如有需要可向場地職員要求協助。如設備嚴重受損則需照價賠償場地損失。檢收場地需時，本公司承諾如無以上情況會於退場後24小時以轉賬方式全數退還。</b>
                <p></p>
                <ol>
                    <li>基於保安理由，場內已裝置閉路電視監察系統(CCTV)進行24小時錄影。</li>
                    <li>場內全面禁煙（包括洗手間）。</li>
                    <li>進場前必須先量度體溫，如體溫超過37.5度，iShow有權拒絕該人士進場，所有費用不作退還。</li>
                    <li>進場時必須先徹底清潔消毒雙手，iSHow有提供消毒搓手液，場內亦有洗手間。</li>
                    <li>iSHow有權拒絕任何有可能對其他人構成疾病傳播、威脅或暴力之人士進場。</li>
                    <li>禁止攜帶違禁品、易燃物、易爆物等危險物。</li>
                    <li>禁止於場地內進行與法律抵觸的活動，違者依法報警處理。</li>
                    <li>請預約租場客戶準時到達，為免影響後續的客戶，我們沒有補時安排。</li>
                    <li>請租場客戶準時交還場地，超時15分鐘以上會以每小時計收取費用 (不足一小時亦當一小時計算)</li>
                    <li>iShow場地及設施會定期檢查，保養及維修。但無法排除突發性設備失靈、損毀或遺失，恕本店未能為此提供任何補償。</li> 
                    <li>請保持地方清潔，波波池內嚴禁飲食 。如有嚴重弄髒（如：嘔吐、玩Cream、亂丟食物蛋糕、打翻飲料、食物殘渣四散等），一經發現，本公司及場地職員有權即時終止場地使用或即時扣除全數場地按金，並會重新收取按金。</li>
                    <li>請於離開前將垃圾放進垃圾桶內。</li>
                    <li>為響應環保，希望顧客自備水杯。如顧客無自備，場地亦可提供是次人數之數量的紙杯 (如適用)</li>
                    <li>請勿擅自移動或拆除場內固定設施，使用場地後所有設備及用品須放回原位及維持原狀。</li>
                    <li>如必需要攜帶寵物，請為各寵物帶上尿片或禮儀褲，保持地方清潔，不可隨處便溺。如發現寵物弄髒地方，預繳的清潔按金不作退還。</li>
                    <li>本場地為迎合不同客戶而設，場內部份設備不適合幼兒使用，請家長或監護人敬請留意。</li>
                    <li>請各家長自行看管及照顧同行之小童，所有使用者均需自行判斷及承擔於iShow所屬場地之所有設施、服務及器材之使用風險，本公司概不承擔任何責任。</li>
                    <li>請勿破壞及擅自帶走室内用品，如傢俱、家電、擺設、遊戲等，並需愛護各項設備。如場內任何物品被損毀或遺失，需照價賠償。</li>
                    <li>如任何人士在場內任何地方遺失或被竊去財物，iShow毋須承擔任何責任。職員將不會受委託保管任何人士之私人財物及物品。</li>
                    <li>請各位配合以上守則，若違反規則，iSHow有權停止其租用場地之權利及禁止進入本場地，已繳付的款項亦不會退回。</li>
                    <li>iShow保留更改以上條款之權利。如有任何爭議，iShow保留就爭議及與此等條款有關的任何問題之最終決定權。</li>
                </ol>
                <div style={{marginBottom: '120px'}}></div>
            </div>
        );
    }


}

export default withLocalize(VenueBooking);