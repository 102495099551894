import React/*, { Component, useEffect }*/ from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
//import { Translate } from "react-localize-redux";

const curHour = new Date().getHours();
const moment = require("moment");

class TimeRangePicker extends React.Component {

    constructor(props) { 
        super(props);
        var isToday = false;
        if (props && props.selectedDate) {
            try{
                isToday = moment().isSame(moment(props.selectedDate.value),'day');
            } catch (e) {
                console.log(e);
            }
        }
        this.state = {
            customStyles: {
                control: styles => ({ ...styles, backgroundColor: 'white' }),
                option: (styles, { data }) => {
                    return {
                      ...styles,
                      borderBottom: data.isdisabled ? "5px red solid" : "5px green solid"
                    };
                },
            },
            options: [
                { label: '00:00 01:00', value: 19, startTime: '00:00:00Z', endTime: '01:00:00Z', isdisabled: isToday && curHour>0},
                { label: '01:00 02:00', value: 20, startTime: '01:00:00Z', endTime: '02:00:00Z', isdisabled: isToday && curHour>1},
                { label: '02:00 03:00', value: 21, startTime: '02:00:00Z', endTime: '03:00:00Z', isdisabled: isToday && curHour>2},
                { label: '03:00 04:00', value: 22, startTime: '03:00:00Z', endTime: '04:00:00Z', isdisabled: isToday && curHour>3},
                { label: '04:00 05:00', value: 23, startTime: '04:00:00Z', endTime: '05:00:00Z', isdisabled: isToday && curHour>4},
                { label: '05:00 06:00', value: 24, startTime: '05:00:00Z', endTime: '06:00:00Z', isdisabled: isToday && curHour>5},
                { label: '06:00 07:00', value: 1, startTime: '06:00:00Z', endTime: '07:00:00Z', isdisabled: isToday && curHour>6},
                { label: '07:00 08:00', value: 2, startTime: '07:00:00Z', endTime: '08:00:00Z', isdisabled: isToday && curHour>7},
                { label: '08:00 09:00', value: 3, startTime: '08:00:00Z', endTime: '09:00:00Z', isdisabled: isToday && curHour>8},
                { label: '09:00 10:00', value: 4, startTime: '09:00:00Z', endTime: '10:00:00Z', isdisabled: isToday && curHour>9},
                { label: '10:00 11:00', value: 5, startTime: '10:00:00Z', endTime: '11:00:00Z', isdisabled: isToday && curHour>10},
                { label: '11:00 12:00', value: 6, startTime: '11:00:00Z', endTime: '12:00:00Z', isdisabled: isToday && curHour>11},
                { label: '12:00 13:00', value: 7, startTime: '12:00:00Z', endTime: '13:00:00Z', isdisabled: isToday && curHour>12},
                { label: '13:00 14:00', value: 8, startTime: '13:00:00Z', endTime: '14:00:00Z', isdisabled: isToday && curHour>13},
                { label: '14:00 15:00', value: 9, startTime: '14:00:00Z', endTime: '15:00:00Z', isdisabled: isToday && curHour>14},
                { label: '15:00 16:00', value: 10, startTime: '15:00:00Z', endTime: '16:00:00Z', isdisabled: isToday && curHour>15},
                { label: '16:00 17:00', value: 11, startTime: '16:00:00Z', endTime: '17:00:00Z', isdisabled: isToday && curHour>16},
                { label: '17:00 18:00', value: 12, startTime: '17:00:00Z', endTime: '18:00:00Z', isdisabled: isToday && curHour>17},
                { label: '18:00 19:00', value: 13, startTime: '18:00:00Z', endTime: '19:00:00Z', isdisabled: isToday && curHour>18},
                { label: '19:00 20:00', value: 14, startTime: '19:00:00Z', endTime: '20:00:00Z', isdisabled: isToday && curHour>19},
                { label: '20:00 21:00', value: 15, startTime: '20:00:00Z', endTime: '21:00:00Z', isdisabled: isToday && curHour>20},
                { label: '21:00 22:00', value: 16, startTime: '21:00:00Z', endTime: '22:00:00Z', isdisabled: isToday && curHour>21},
                { label: '22:00 23:00', value: 17, startTime: '22:00:00Z', endTime: '23:00:00Z', isdisabled: isToday && curHour>22},
                { label: '23:00 00:00', value: 18, startTime: '23:00:00Z', endTime: '00:00:00Z', isdisabled: false},
            ]
        };
    }


    setDisableOption(startValue, endValue){
        this.state.options.forEach((item,i)=>{ 
            if(item.value >= startValue && item.value <= endValue){
                item.isdisabled = true;
            }
        });
        //console.log(this.state.options);
    }

    getOption(){
        return this.state.options;
    }

    handleTimeOnchange = (e) => {
        //console.log(e);
        this.props.onChange(e);
    }

    render() {
        var value = this.props.value;
        var placeholderButtonLabel = this.props.placeholderButtonLabel;

        return (
            <div className="TimeRangePicker">
                <ReactMultiSelectCheckboxes placeholderButtonLabel={placeholderButtonLabel} styles={this.state.customStyles} width="500px" options={ this.props.option } value={ value } onChange={ this.handleTimeOnchange.bind(this) } isOptionDisabled={(option) => option.isdisabled}/>
            </div>
        );
    }
}

export default TimeRangePicker;