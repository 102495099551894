import React, {Fragment} from 'react';

class Dummy extends React.Component {
    componentDidMount(){
        this.props.updateState(this.props.state);
    }

    render() {
        return (<Fragment></Fragment>);
    }
}

export default Dummy;