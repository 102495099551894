import React, { Component } from 'react';
//import './PasswordStrengthMeter.css';
import Constants from '../constants';
import { withLocalize } from 'react-localize-redux';
import { passwordStrength } from 'check-password-strength';

class PasswordStrengthMeter extends Component {

  createPasswordLabel = (result) => {
    switch (result.id) {
      default:
      case 0:
        return this.props.translate('password.strength.weak');
      case 1:
        return this.props.translate('password.strength.fair');
      case 2:
        return this.props.translate('password.strength.good');
      case 3:
        return this.props.translate('password.strength.strong');
    }
  }

  createPasswordTips = (result) => { console.log(this.props);
    switch (result.id) {
      case 0:
      case 1:
        if (result.contains.indexOf("lowercase") === -1){
          return this.props.translate("password.errMsg.strength.atLeastOneLowercase");
        } else if(result.contains.indexOf("uppercase") === -1){
          return this.props.translate('password.errMsg.strength.atLeastOneUppercase');
        /*} else if(result.contains.indexOf("symbol") === -1){
          return this.props.translate('password.errMsg.strength.atLeastOneSymbol');*/
        } else if(result.contains.indexOf("number") === -1){
          return this.props.translate('password.errMsg.strength.atLeastOneNumber');
        } else if(result.length<8) {
          return this.props.translate('password.errMsg.strength.atLeastCharacterLength');
        }
        return '';        
      case 2:
        return '';
      case 3:
        return '';
      default:
        return '';
    }
  }

  render() {
    const { password } = this.props;
    //const testedResult = zxcvbn(password);
    const testedResult = passwordStrength(password);
    return (
      <div className="password-strength-meter"><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/PasswordStrengthMeter.css?t="+Constants.VERSION}></link>

        <progress
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
          value={testedResult.id}
          max="3"
        />
        <br />
        <label
          className="password-strength-meter-label"
        >
          {password && (
            <>
              <strong>{this.props.translate('password.strength.label')}</strong> {this.createPasswordLabel(testedResult)} &nbsp;&nbsp;
              <span className="text-danger">{this.createPasswordTips(testedResult)}</span>
            </>
          )}
        </label>
      </div>
    );
  }
}

export default withLocalize(PasswordStrengthMeter);
