import React, { Fragment } from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { Container, Row, Col,/*Collapse,*/Button,Form,Card,InputGroup,FormControl,} from "react-bootstrap";
//import Moment from 'react-moment';
import Constants from "../../constants";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { /*UserServices,*/ RoomBookingServices } from "../../services";
import { Prompt } from "../../utils";

import TimeRangePicker from '../../components/TimeRangePicker';
const moment = require("moment");

class RoomDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomStatus: true,
            roomId: this.props.match.params.roomId,
            rooms: [],
            startTime: { value: this.props.match.params.startTime },
            endTime: { value: this.props.match.params.endTime },
            startDate: { value: this.props.match.params.startDate ? this.props.match.params.startDate : new Date()},
            disableRoomsId: [],
            totalPrice: 0,
            startTimeHour: { value: "" },
            startTimeMin: { value: "" },
            endTimeHour: { value: "" },
            endTimeMin: { value: "" },
            searchTimeValue: [],
            searchTime: [],
            options: [],
            disableTnC: true,
            guidelines: '/venueBookingCondition'
        };
    }


    async componentDidMount() {
        //this.splitTime();
        await this.getSearchTime();
        this.loadDetails();
        //console.log(this.state.searchTime);
    }

    setSearchTime = (e) => {
        this.setState({ searchTime: e }, this.setSearchTimeValue);
    }

    setSearchTimeValue(){
        var value = this.state.searchTime.map(function(item){ return item.value; });

        value.sort((a, b) => a - b);
        //console.log(value);

        this.setState(
            { searchTimeValue: value },
            this.loadDisableRooms
        );
    }
    
    getSearchTime(){
        var searchTimeValue = this.props.match.params.searchTimeValue;

        if(searchTimeValue){
            var value = searchTimeValue.split(",");
            //console.log(value);
            
            const timeRangePicker = new TimeRangePicker();
            var options = timeRangePicker.getOption();
            var timeList = [];
            
            for (let i = 0; i < value.length; i++) {
                var count = parseInt(value[i]);
                //console.log(count);
                timeList[i] = options[count - 1];
                //console.log(options[count - 1]);
            }
            
            //console.log(timeList);
            this.setState({ searchTime: timeList, searchTimeValue: value});
        }
        
    }

    splitTime() {
        if (this.state.startTime.value && this.state.endTime.value) {
            var startTime = this.state.startTime.value.split(":");
            var endTime = this.state.endTime.value.split(":");

            var state = this.state;
            state["startTimeHour"].value = startTime[0];
            state["startTimeMin"].value = startTime[1];
            state["endTimeHour"].value = endTime[0];
            state["endTimeMin"].value = endTime[1];
        }

        this.setState(state);
    }

    autoEndTimeByStartTime(startTimeHour, startTimeMin) {
        var state = this.state;

        var time = this.forceTime(startTimeHour, startTimeMin);
        startTimeHour = time[0];
        startTimeMin = time[1];

        // add one more hour
        var endTimeHour = moment(startTimeHour + startTimeMin, "HHmm")
            .add(1, "H")
            .format("HH");
        var endTimeMin = moment(startTimeHour + startTimeMin, "HHmm")
            .add(1, "H")
            .format("mm");

        state["startTimeHour"].value = startTimeHour;
        state["startTimeMin"].value = startTimeMin;
        state["endTimeHour"].value = endTimeHour;
        state["endTimeMin"].value = endTimeMin;

        this.setState(state);
    }

    autoEndTime(startTimeHour, startTimeMin, endTimeHour, endTimeMin) {
        var state = this.state;

        if (startTimeHour === endTimeHour && startTimeMin === endTimeMin) {
            endTimeHour = moment(endTimeHour + endTimeMin, "HHmm")
                .add(1, "H")
                .format("HH");
        } else if (startTimeMin === "00" && endTimeMin !== "00") {
            endTimeHour = moment(endTimeHour + endTimeMin, "HHmm")
                .add(1, "H")
                .format("HH");
            endTimeMin = "00";
        } else if (startTimeMin === "30" && endTimeMin === "00") {
            endTimeMin = "30";
        } else if (
            startTimeMin === "30" &&
            endTimeMin !== "30" &&
            endTimeMin !== "00"
        ) {
            endTimeHour = moment(endTimeHour + endTimeMin, "HHmm")
                .add(1, "H")
                .format("HH");
            endTimeMin = "30";
        }
        state["endTimeHour"].value = endTimeHour;
        state["endTimeMin"].value = endTimeMin;

        this.setState(state);
    }

    forceTime(hour, minute) {
        if (minute < 15) {
            minute = "00";
        }
        if (minute >= 15 && minute <= 30) {
            minute = "30";
        }
        if (minute > 30 && minute < 45) {
            minute = "30";
        }
        if (minute >= 45 && minute < 60) {
            hour = moment(hour + minute, "HHmm")
                .add(1, "H")
                .format("HH");
            minute = "00";
        }
        return [hour, minute];
    }

    validateNumber(input) {
        if (isNaN(input)) {
            Prompt.error(
                "Error",
                this.props.translate("common.errMsg.INVALID_TIME"),
                "OK"
            );
            return false;
        }
        return true;
    }

    validateStartTime(startTimeHour, startTimeMin) {
        if (startTimeHour < 10 || startTimeHour > 19) {
            Prompt.error(
                "Error",
                this.props.translate("common.errMsg.INVALID_TIME_02"),
                "OK"
            );
            return false;
        }
        if (startTimeMin < 0 || startTimeMin > 59) {
            Prompt.error(
                "Error",
                this.props.translate("common.errMsg.INVALID_TIME_02"),
                "OK"
            );
            return false;
        }
        return true;
    }

    validateEndTime(startTimeHour, endTimeHour, endTimeMin) {
        if (endTimeHour > 20 || (endTimeHour === 20 && endTimeMin > 0)) {
            Prompt.error(
                "Error",
                this.props.translate("common.errMsg.INVALID_TIME_02"),
                "OK"
            );
            return false;
        }
        if (endTimeHour < startTimeHour || endTimeHour < 10) {
            Prompt.error(
                "Error",
                this.props.translate("common.errMsg.INVALID_TIME_RANGE"),
                "OK"
            );
            return false;
        }
        return true;
    }

    handleDateChange = (date) => {
        //console.log(date);
        var state = this.state;
        state["startDate"].value = date;
        this.setState(state);
        this.loadDisableRooms();
    };

    handlePriceChange() {
        /*if(this.state.searchTime.length === 0){
            this.setState({totalPrice: this.state.rooms[0].price});
        } else{*/
        //if (this.state.searchTime.length>0) {
            var hours = this.state.searchTime.length;
            this.setState({totalPrice: this.state.rooms[0].price * hours});
        //}
        /* if (!this.state.endTime.value || !this.state.startTime.value) {
            this.setState({totalPrice: this.state.rooms[0].price});
            //console.log(this.state.totalPrice);
        } else {
            var endTime = moment(
                this.state.endTime.value,
                "HH:mm"
            );
            var startTime = moment(
                this.state.startTime.value,
                "HH:mm"
            );
            var hours = endTime.diff(startTime, "hours");
            this.setState({totalPrice: this.state.rooms[0].price * hours});
        } */
    }

    handleChange = (event) => {
        var state = this.state;
        state[event.target.id].value = event.target.value;
        this.setState(state);

        var startTimeHour = this.state.startTimeHour.value;
        var startTimeMin = this.state.startTimeMin.value;
        var endTimeHour = this.state.endTimeHour.value;
        var endTimeMin = this.state.endTimeMin.value;

        switch (event.target.id) {
            case "startTimeHour":
                if(startTimeHour ===  "1"){
                    break;
                }
            case "startTimeMin":
                this.validateNumber(event.target.value);
                
                if (startTimeHour && startTimeMin) {
                    switch (startTimeMin) {
                        /* case "00":
                        case "30": */
                        case "0": case "1": case "2": case "3": case "4": case "5":
                            break;
                        default:
                            if (!(startTimeHour === "0" || startTimeHour === "1")) {
                                this.autoEndTimeByStartTime(startTimeHour, startTimeMin);
                                state["startTime"].value = this.state.startTimeHour.value + ":" +　this.state.startTimeMin.value;
                                state["endTime"].value = this.state.endTimeHour.value + ":" +　this.state.endTimeMin.value;
                                this.setState(state);
                            }
                            if (this.validateSearchTime()) {
                                state["startTime"].value = this.state.startTimeHour.value + ":" +　this.state.startTimeMin.value;
                                state["endTime"].value = this.state.endTimeHour.value + ":" +　this.state.endTimeMin.value;
                                this.setState(state);
                                this.handlePriceChange();
                                this.loadDisableRooms();
                            }
                    }
                }
                break;
            case "endTimeHour":
                if(endTimeHour ===  "1"){
                    break;
                }
            case "endTimeMin":
                this.validateNumber(event.target.value);
                //console.log(endTimeMin);
                switch (endTimeMin) {
                    /* case "00":
                    case "30": */
                    case "": case "0": case "1": case "2": case "3": case "4": case "5":
                        break;
                    default:
                        if (startTimeMin !== endTimeMin || (startTimeHour === endTimeHour && startTimeMin === endTimeMin)) {
                            this.autoEndTime(
                                startTimeHour,
                                startTimeMin,
                                endTimeHour,
                                endTimeMin
                            );
                            state["startTime"].value = this.state.startTimeHour.value + ":" +　this.state.startTimeMin.value;
                            state["endTime"].value = this.state.endTimeHour.value + ":" +　this.state.endTimeMin.value;
                            this.setState(state);
                        }
                        if (this.validateSearchTime()) {
                            state["startTime"].value = this.state.startTimeHour.value + ":" +　this.state.startTimeMin.value;
                            state["endTime"].value = this.state.endTimeHour.value + ":" +　this.state.endTimeMin.value;
                            this.setState(state);
                            this.handlePriceChange();
                            this.loadDisableRooms();
                        }
                }
                break;
            default:
                break;
        }
    };

    checkAvailable(arr){
        //var arr = arr;

        var error = arr.filter((num, i) => (num - arr[i - 1] !== 1) );
        //console.log(error);
        
        if(error.length > 1){
            return false;
        } else{
            return true;
        }
    }

    handleSubmit = (event) => {
        //await this.loadDisableRooms();
        var Date = moment(this.state.startDate.value).format("YYYY-MM-DD");
        var value = this.state.searchTime.map(function(item){ return item.value; });

        value.sort((a, b) => a - b);
        //console.log(value);

        const timeRangePicker = new TimeRangePicker();
        var options = timeRangePicker.getOption();
        //console.log(options);

        var roomId = this.state.rooms[0]._id;
        //console.log(roomId);

        //console.log(options.filter(function(item) { if(item.value === value[0]) return item; } ).map(function(item){ return item.startTime; })[0]);

        var startTime = Date + "T" + options.filter((item)=>item.value === value[0]).map(function(item){ return item.startTime; })[0];
        //console.log(startTime);
        var endTime = Date + "T" + options.filter((item)=>item.value === value[value.length - 1]).map(function(item){ return item.endTime; })[0];
        //console.log(endTime);

        if(this.checkAvailable(value)){
            var requestData = {
                roomId: roomId,
                startTime: startTime,
                endTime: endTime
            };
            //console.log(requestData);
            RoomBookingServices.getAvailableRoom(requestData, this.loadDisableRoomsCallback);

            setTimeout(
                () => {
                    //console.log(this.state.disableRoomsId);
    
                    if(this.state.disableRoomsId.length === 0){
                        this.setState(
                            { searchTimeValue: value },
                            this.redirect
                        );
                    } else{
                        Prompt.error(
                            this.props.translate('common.sysmsg'),
                            this.props.translate('common.errMsg.INVALID_TIME_SLOT_BOOKED'),
                            this.props.translate('common.ok'));
                    }
                }, 
                3000
            )
        } else{
            Prompt.error(
                this.props.translate('common.sysmsg'),
                this.props.translate('common.errMsg.INVALID_TIME_SLOT'),
                this.props.translate('common.ok'));
        }
        
    }

    redirect(){
        this.props.history.push(Constants.CONTEXT_PATH + "bookingConfirmation/" + this.state.rooms[0]._id +
                "/" + this.state.searchTimeValue +
                "/" + moment(this.state.startDate.value).format("YYYY-MM-DD"));
    }

    validateSearchTime() {
        var startTimeHour = this.state.startTimeHour.value;
        var startTimeMin = this.state.startTimeMin.value;
        var endTimeHour = this.state.endTimeHour.value;
        var endTimeMin = this.state.endTimeMin.value;

        return (
            this.validateStartTime(startTimeHour, startTimeMin) &&
            this.validateEndTime(startTimeHour, endTimeHour, endTimeMin) && this.state.startDate.value
        );
    }

    loadDetails = () => {
        //console.log(this.state.roomId);
        let requestData = "/" + this.state.roomId;
        RoomBookingServices.getRoom(requestData, this.loadRoomsCallback);
    };

    /* loadDisableRooms = () => {
        var Date = moment(this.state.startDate.value).format("YYYY-MM-DD");
        var sTime = this.state.startTime.value;
        var eTime = this.state.endTime.value;

        //console.log(this.state.startDate.value);
        var startTime =
            moment(Date + sTime, "YYYY-MM-DDh:mmA").format("YYYY-MM-DDTHH:mm:ss") + "Z";
        var endTime =
            moment(Date + eTime, "YYYY-MM-DDh:mmA").format("YYYY-MM-DDTHH:mm:ss") + "Z";

        var requestData = {
            startTime: startTime,
            endTime: endTime,
        };
        //console.log(requestData);
        RoomBookingServices.getAvailableRoom(requestData, this.loadDisableRoomsCallback);
    }; */

    loadDisableRooms = () => {
        var Date = moment(this.state.startDate.value).format("YYYY-MM-DD");
        var value = this.state.searchTime.map(function(item){ return item.value; });

        value.sort((a, b) => a - b);
        //console.log(value);

        /*const timeRangePicker = new TimeRangePicker({selectedDate:this.state.startDate});
        var options = timeRangePicker.getOption();*/

        var roomId = this.state.rooms[0]._id;
        //console.log(roomId);

        var startTime = Date + "T00:00:00Z";
        //console.log(startTime);
        var endTime = Date + "T23:59:59Z";
        //console.log(endTime);

        if(this.checkAvailable(value)){
            var requestData = {
                roomId: roomId,
                startTime: startTime,
                endTime: endTime
            };
            //console.log(requestData);
            RoomBookingServices.getAvailableRoom(requestData, this.loadDisableRoomsCallback);

            this.handlePriceChange();
        } else{
            let searchTimes = this.state.searchTime;
            searchTimes.pop();
            this.setState({searchTime:searchTimes}, this.setSearchTimeValue);
            Prompt.error(
                this.props.translate('common.sysmsg'),
                this.props.translate('common.errMsg.INVALID_TIME_SLOT'),
                this.props.translate('common.ok'));
        }    
    }

    loadRoomsCallback = (resp) => {
        //console.log(resp);
        if (resp !== null && resp !== undefined && resp.ok) {
            this.setState({ rooms: resp.data.rooms });
            this.handlePriceChange();
            this.loadDisableRooms();

            this.setState({
                roomStatus: this.state.disableRoomsId.length === 0 ?
                    true : this.state.disableRoomsId.includes(resp.data.rooms._id),
            });
        } else {
            Prompt.error('Error',resp.error.message,'OK');
        }
    };

    loadDisableRoomsCallback = (resp) => {
        //console.log(resp);
        if (resp !== null && resp !== undefined && resp.ok) {
            this.setState({ disableRoomsId: resp.data.disableRoomTimeSlot }, this.setDisableTimeSlot);
            //this.loadDetails();
            //console.log(this.state.options);
        } else {
            Prompt.error('Error',resp.error.message,'OK');
        }
    };

    setDisableTimeSlot(){
        var disable_start_end_time = this.state.disableRoomsId;
        //console.log(disable_start_end_time);

        const timeRangePicker = new TimeRangePicker({selectedDate:this.state.startDate});
        var options = timeRangePicker.getOption();
        
        disable_start_end_time.forEach((item,i) =>{ 
            var startTime = options.filter(function(o) { return(o.startTime === moment(item.startTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss") + "Z");} ).map(function(o) {return o.value;})[0];
            //console.log(startTime);
            var endTime = options.filter(function(o) { return(o.endTime === moment(item.endTime, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss") + "Z");} ).map(function(o) {return o.value;})[0];

            timeRangePicker.setDisableOption(startTime, endTime);
        });

        //console.log(options);
        this.setState({options: options});
    }

    labelWithLink() {
        return (
        <span> <Translate id="events.request.room.spanTextForCondition" /> "
          <a
            href={this.state.guidelines}
            target="_blank"
            rel="noopener noreferrer">
            <Translate id="events.request.room.Condition" />
          </a>
          "
        </span>
        );
    }

    handleCheckboxChange = (evt) => {
        var state = this.state;
        state.disableTnC = !evt.target.checked;
        this.setState(state);
        return true;
    };

    render() {
        return (
            <Fragment>
                <link rel="stylesheet" href={Constants.CONTEXT_PATH + "css/events.css?t=" + Constants.VERSION} ></link>
                <Container className="p-0">
                    <div className="pageTitle">
                        <Row>
                            <h1><Translate id="common.appName" /></h1>
                        </Row>
                    </div>

                    {this.state.rooms.map((item) => (
                        <Card className="card" style={{ width: "100%" }} >
                            <Card.Body>
                                <Card.Title> {item.name} </Card.Title>
                                <Card.Text className="m-0">
                                    <Translate id="events.request.room.distinct" /> : {item.district}
                                </Card.Text>

                                <Card.Text>
                                    <Translate id="events.request.room.address" /> : {item.address}
                                </Card.Text>

                            </Card.Body>
                            
                            <div style={{ 
                                height: '30vh',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundImage: `url(data:`+item.upload.mimetype+';base64,'+item.picture+`)` 
                            }}>
                            </div>
                            {/* <div className="text-center">
                                <Card.Img variant="top" src={'data:'+item.upload.mimetype+';base64,'+item.picture} className="roomDetailsImg"/>
                            </div> */}

                            <Card.Body className="rwd-card-body" >
                                <Card.Text>
                                    <Translate id="events.request.room.details" />
                                    <div dangerouslySetInnerHTML={{__html: item.details}}></div>
                                </Card.Text>
                                {/* <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Available</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>10:00-10:30</td>
                                                    <td className="bg-warning"></td>
                                                </tr>
                                            </tbody>
                                        </Table> */}
                                <Row className="mb-2">
                                    <Col xs={9} sm={9} md={9} lg={9}>
                                        <Row>
                                            <Col xs={5} sm={5} md={3} lg={3} className="pr-0" >
                                                <Translate id="events.request.room.date" /> :
                                            </Col>
                                            <Col xs={7} sm={7} md={9} lg={9} className="pl-0" >
                                                <InputGroup className="mb-3" >
                                                    <FormControl
                                                        placeholder=""
                                                        className="p-2"
                                                        value={moment(this.state.startDate.value).format("YYYY-MM-DD")} />
                                                    <DatePicker
                                                        value={this.state.startDate.value}
                                                        selected={new Date(this.state.startDate.value)}
                                                        onChange={this.handleDateChange}
                                                        customInput={
                                                            <Button variant="primary" style={{ zIndex: 0 }} className="available" >
                                                                <span>
                                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                                </span>
                                                            </Button>
                                                        }
                                                        name="startDate"
                                                        dateFormat="MM/dd/yyyy"
                                                        minDate={new Date()}
                                                        maxDate={addDays(new Date(), 7)} />
                                                </ InputGroup>
                                            </Col>
                                        </Row>
                                        <div className="roomDetails-timerangepicker">
                                            <TimeRangePicker placeholderButtonLabel={this.props.translate("events.request.book.choose_time_search")} option={this.state.options} value={this.state.searchTime} onChange={(e) => this.setSearchTime(e) } />
                                        </div>
                                        
                                       {/*  <Row className="px-4" >
                                            <Translate id="events.starttime" />
                                        </Row>

                                        <Row className="px-2" >
                                            <Col className="p-0" >
                                                <Form.Group controlId="startTimeHour" >
                                                    <Form.Control autoFocus type="startTimeHour"
                                                        onChange={this.handleChange}
                                                        value={this.state.startTimeHour.value}
                                                        name="startTimeHour"
                                                        isInvalid={!!this.state.startTimeHour.vs}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className="p-0 text-center" >
                                                :
                                                </Col>
                                            <Col className="p-0" >
                                                <Form.Group controlId="startTimeMin" >
                                                    <Form.Control autoFocus type="startTimeMin"
                                                        onChange={this.handleChange}
                                                        value={this.state.startTimeMin.value}
                                                        name="startTimeMin"
                                                        isInvalid={!!this.state.startTimeMin.vs}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className="mt-2 px-4" >
                                            <Translate id="events.endtime" />
                                        </Row>

                                        <Row className="px-2" >
                                            <Col className="p-0" >
                                                <Form.Group controlId="endTimeHour" >
                                                    <Form.Control autoFocus type="endTimeHour"
                                                        onChange={this.handleChange}
                                                        value={this.state.endTimeHour.value}
                                                        name="endTimeHour"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className="p-0 text-center" >
                                                :
                                                </Col>
                                            <Col className="p-0" >
                                                <Form.Group controlId="endTimeMin" >
                                                    <Form.Control autoFocus type="endTimeMin"
                                                        onChange={this.handleChange}
                                                        value={this.state.endTimeMin.value}
                                                        name="endTimeMin"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} className="pl-0" >
                                        <Table bordered className="w-100 h-100 text-center d-flex flex-wrap align-content-center justify-content-center rounded" >
                                            <div><Translate id="events.request.room.total" /> : {" "} </div>
                                            <div > HKD$ {this.state.totalPrice} </div>
                                        </Table>
                                    </Col>
                                </Row>

                                <Row>
                                    <Form.Group align="left">

                                        <Form.Check
                                        required
                                        feedback={this.props.translate("events.request.room.agreeCondition")}
                                        onChange={this.handleCheckboxChange}
                                        label={this.labelWithLink()}
                                        >
                                        </Form.Check>

                                    </Form.Group>
                                </Row>
                                <Row>
                                    {/* <Col>
                                        <Translate id="events.request.room.status" /> ﹔
                                            {this.state.roomStatus ? (
                                            <Translate id="events.request.room.statusDetails.OK" />) :
                                            (<Translate id="events.request.room.statusDetails.Booked" />)}
                                    </Col> */}

                                    <Col>
                                        <Button variant="success" size="lg" block onClick={this.handleSubmit}
                                            /* this.props.history.push(Constants.CONTEXT_PATH + "bookingConfirmation/" + item._id +
                                                "/" + this.state.startTime.value +
                                                "/" + this.state.endTime.value +
                                                "/" + moment(this.state.startDate.value).format("YYYY-MM-DD"));
                                            } */
                                            /* disabled={!this.state.roomStatus || !this.state.startTime.value || !this.state.endTime.value}  */
                                            disabled={!this.state.roomStatus || this.state.searchTime.length <= 0 || this.state.disableTnC} 
                                        >
                                            <Translate id="events.request.room.submit" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )
                    )
                    }

                    <Row className="mb-5"></Row>
                    <Row className="mb-5"></Row>
                    <Row className="mb-5"></Row>
                </Container>
            </Fragment>
        );
    }
}

export default withLocalize(RoomDetails);