import React, { Fragment } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Constants from '../constants';
import {constants as GlobalConstants} from 'aiot-room-booking-common';

const HeadPane = ({ state, languages, activeLanguage, setActiveLanguage, logoutHandler }) => (
    <Fragment><Navbar collapseOnSelect className="fwd-aap-navbar">
      <Navbar.Brand>
        <Row style={{height: 50}}>
          <Col className="headTitleContainer" >
          <Link to={Constants.CONTEXT_PATH}><img className="App-logo" src={Constants.CONTEXT_PATH+'img/aiot_logo_transparent.png'} alt="AIOT logo"/>
          </Link>
          </Col>
        </Row>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          {(state.isLoggedIn && state.userData && state.userData.roles && state.userData.roles[0].slug!==GlobalConstants.PERMISSIONS.TEMP ) ? (
            <Nav.Item key={1}>
              <Nav.Link key={1} eventKey={1} onClick={() => logoutHandler()}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
              </Nav.Link>
            </Nav.Item>
          ) : (''
          )}
          {languages.map((lang, index) => (
            <Nav.Item key={index+1}>
              <Nav.Link key={index + 1} eventKey={index + 1} 
            className={activeLanguage!==undefined?(lang.code===activeLanguage.code?'active':''):(lang.code===languages[0].code?'active':'')} 
            onClick={() => setActiveLanguage(lang.code)}>{lang.name}</Nav.Link>
            </Nav.Item>
          ))}
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </Fragment>
);

export default withLocalize(HeadPane);
