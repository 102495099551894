import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Alert, Col, Button, Form, Row, Card, ListGroup } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { AuthServices, UserServices } from '../../services';
import { Prompt } from '../../utils';
import Constants from '../../constants';
import uuidv1 from 'uuid/v1';
import { constants as GlobalConstants } from 'aiot-room-booking-common';
import FacebookLogin from 'react-facebook-login';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertClass: '',
      alertMessage: '',
      redirectToReferrer: props.isLoggedIn,
      redirectToEvents: false,
      email: { value: '', vs: null, errormsg: '' },
      password: { value: '', vs: null, errormsg: '' },
      fbKey: '',
      role: null,
      displayName: "",
      name: ""
    };
  }

  async componentDidMount() {
    //console.log(this.props.isLoggedIn);
    if (this.props.isLoggedIn) {
      this.setState({redirectToEvents: true});
    } else {
      //var requestData = '';

      //UserServices.getUserRoles(requestData, this.getUserRolesCallback);
    }

  }

  getUserRolesCallback = respData => {

    if (respData.ok) {
      var state = this.state;
      state.role = respData.data.userRoles[0]._id;
      this.setState(state);

    
    } else {
  
      Prompt.error('Error', respData.error.message, 'OK');

    }
  }

  validateEmail() {
    var state = this.state;
    if (this.state.email.value.length <= 0) {
      state.email.vs = 'error';
      state.email.errormsg = <Translate id="login.errMsg.noUsername" />;
      this.setState(state);
      return false;
    } else {
      state.email.vs = null;
      state.email.errormsg = '';
      this.setState(state);
      return true;
    }
  }
  validatePassword() {
    var state = this.state;
    if (this.state.password.value.length > 0) {
      state.password.vs = null;
      state.password.errormsg = '';
      this.setState(state);
      return true;
    } else {
      state.password.vs = 'error';
      state.password.errormsg = <Translate id="login.errMsg.noPassword" />;
      this.setState(state);
      return false;
    }
  }
  validateForm() {
    return this.validateEmail() && this.validatePassword();
  }

  handleChange = event => {
    var state = this.state;
    state[event.target.id].value = event.target.value;
    this.setState(state);
    switch (event.target.id) {
      case 'email':
        this.validateEmail();
        break;
      case 'password':
        this.validatePassword();
        break;
      default:
        break;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      var requestData = {
          email: this.state.email.value,
          password: this.state.password.value
      };
      AuthServices.login(requestData, this.postLoginCallback);
    }
  };

  postLoginCallback = respData => {
    if (respData.ok) { 
        console.log(respData.data.authuser);
        var userData = respData.data.authuser;
        userData['_t'] = respData.data.token;
        localStorage.setItem(Constants.SESSION_KEY_USER_DATA, JSON.stringify(userData));
        this.props.userHasLoggedIn(true);
        this.props.setUser(respData.data.authuser);
        this.setState({redirectToEvents:true});
    } else {
      //console.log(respData);
      if (respData.error.code === GlobalConstants.MESSAGES.ERROR.USER_NOT_EXISTED.code){
      
          var requestData = {
            displayName: this.state.displayName,
            name: this.state.name,
            email: this.state.email.value,
            password: uuidv1(),
            roles: this.state.role,
            socialKeys: {fbKey: this.state.fbKey}
          };
          if (this.props.userData) {

            var retAns = Prompt.question(this.props.translate('common.sysmsg'),
                            this.props.translate("common.transferAccount"),
                            this.props.translate("common.yes"),
                            this.props.translate("common.no"));
              if (retAns) {
                requestData.userId = this.props.userData._id;
                UserServices.updateUser(requestData, this.postLoginCallback);
              } else {
                UserServices.register(requestData, this.postLoginCallback);
              }

            } else {  
              UserServices.register(requestData, this.postLoginCallback);
            }
  
      } else {
          Prompt.error(
            this.props.translate('common.sysmsg'),
            this.props.translate('common.errMsg.'+respData.error.message),
            this.props.translate('common.ok'));
        }
    }
  };

  facebookLoginCallback = (response) => {
    //console.log(response);
    var state = this.state;
    state.fbKey = response.id;
    state.name = response.email;
    state.displayName = response.name;
    state.email.value = response.email;
    this.setState(state);
    var requestData = {
      fbKey: this.state.fbKey
    }
    AuthServices.fbLogin(requestData, this.postLoginCallback);

  };

  render() {
    if (this.state.redirectToEvents === true) {
      return <Redirect to={this.props.history.goBack()} />;
    }

    return (
      <div className="Login"><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/login.css?t="+Constants.VERSION}></link>
        <div className="pageTitle">
          <h1>
            <Translate id="login.pageTitle" />
          </h1>
        </div>
        {this.state.alertClass ? (
          <Alert bsStyle={this.state.alertClass}>
            <strong>{this.state.alertMessage}</strong>
          </Alert>
        ) : (
          ''
        )}
        <div className="div-form">
          <div className="div-form-inner login-form">
            <form onSubmit={this.handleSubmit}>
              <Form.Group controlId="email" size="lg">
                <Form.Control
                  autoFocus
                  type="email"
                  value={this.state.email.value}
                  onChange={this.handleChange}
                  className="loginpage-form"
                  isInvalid={!!this.state.email.vs}
                  placeholder={this.props.translate("login.textfield.name")}
                />
                <Form.Control.Feedback type="invalid">{this.state.email.errormsg}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password" size="lg">
                <Form.Control
                  value={this.state.password.value}
                  onChange={this.handleChange}
                  type="password"
                  className="loginpage-form"
                  isInvalid={!!this.state.password.vs}
                  placeholder={this.props.translate("login.textfield.password")}
                />
                <Form.Control.Feedback type="invalid">{this.state.password.errormsg}</Form.Control.Feedback>
              </Form.Group>
              <Button block size="lg" type="submit" className="btn-login available">
                <Translate id="login.button.login" />
              </Button>
              <Button variant="link" href="/forgotPassword">{this.props.translate("common.forgotPassword")}</Button>
              <Button variant="link" href="/register">{this.props.translate("common.register")}</Button>
              <Row><Col>{this.props.translate("common.orSeparator")}</Col></Row>
              <Row>
                <Col>
                <Card className="card-others">
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <FacebookLogin
                        appId={Constants.APP_ID.FB}
                        callback={this.facebookLoginCallback}
                        fields="name,email,picture"
                        icon="fa-facebook"
                        textButton={this.props.translate("login.button.facebook")}
                      />
                  </ListGroup.Item>
                  <ListGroup.Item>
                </ListGroup.Item>
                </ListGroup>
                </Card>
              </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(Login);
