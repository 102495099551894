import Constants from '../constants';
import { Commons } from './Commons';

const serviceUrl = Constants.API.USER_SERVICES;

export {
    register,
    updateUser,
    sendNotification,
    updateNotification,
    getUsers,
    getUserRoles,
    activateUser
};

function register(requestData, callback) {
    var callingService = serviceUrl + 'register';
    //console.log('calling '+callingService);
    Commons.post(callingService, requestData, callback);
}

function updateUser(requestData, callback) {
    var callingService = serviceUrl + 'updateUser/' + requestData.userId;
    delete requestData.userId;
    //console.log('calling '+callingService);
    Commons.post(callingService, requestData, callback);
}

function activateUser(requestData, callback) {
    var callingService = serviceUrl + 'activateUser/' + requestData.userId;
    delete requestData.userId;
    //console.log('calling '+callingService);
    Commons.post(callingService, requestData, callback);
}

function sendNotification(requestData, callback) {
    var callingService = serviceUrl + 'sendNotification';
    //console.log('calling '+callingService);
    Commons.post(callingService, requestData, callback);
}

function updateNotification(requestData, callback) {
    var callingService = serviceUrl + 'updateNotification/' + requestData.notificationId;
    //console.log('calling '+callingService);
    Commons.post(callingService, requestData, callback);
}

function getUsers(requestData, callback) {
    var callingService = serviceUrl + 'users';
    if (requestData) {
        callingService += requestData;
    }


    //console.log('calling '+callingService);
    Commons.get(callingService, callback);
}


function getUserRoles(requestData, callback) {
    var callingService = serviceUrl + 'userRoles';
    if (requestData) {
        callingService += requestData;
    }
    //console.log('calling ' + callingService);
    Commons.get(callingService, callback);
}
