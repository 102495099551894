import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Col, Row, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { UserServices } from '../../services';
import { Prompt } from '../../utils';
import Constants from '../../constants';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };
  }

  async componentDidMount() {
      this.loadUser();
  }

  loadUser() {
    var requestData = '?email=' + this.props.userData.email;
    UserServices.getUsers(requestData, this.getUsersCallback);
  }

  getUsersCallback=(resp)=>{

    if (resp && resp.ok) {
      //console.log(resp);
      this.setState({isActive: resp.data.users[0].isActive});

    } else {
      Prompt.error('Error',resp.error.message,'OK');

    }
  }

  handleSubmit = event =>{
    var content = this.props.translate("register.activate.emailContent", { activationLink: Constants.DOMAIN + "activate/{notificationId}"}, { renderInnerHtml: false });
      var expiryDate = new Date();

      // add a day
      expiryDate.setDate(expiryDate.getDate() + 1);

      var requestData = {
        emailTo: this.props.userData.email,
        emailFrom: this.props.translate("register.activate.emailFrom"),
        subject: this.props.translate("register.activate.emailSubject"),
        content: content,
        expiryDate: expiryDate.toISOString(),
        templateNum: 'SYS-003'
      };
      UserServices.sendNotification(requestData, this.postSendNotificationCallback);
  }

  postSendNotificationCallback = respData => {
    if (respData.ok) { 
      Prompt.success('Success', this.props.translate("common.successMsg.ACTIVATE_MAIL_SENT") ,'OK');
    } else {
      Prompt.error('Error',respData.error.message,'OK');
      var state = this.state;
      state.isLoading = false;
      this.setState(state);
    }
  };


  render() {

    return (
      <div className="Login"><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/login.css?t="+Constants.VERSION}></link>
        <div className="pageTitle">
          <h1>
            <Translate id="login.pageTitle" />
          </h1>
        </div>
        <ListGroup className="list-group-style">
            <ListGroupItem className="px-3">
                <Row>
                    <Col xs={4} sm={4} md={4} lg={4}><h5><Translate id="common.nickname"/>:</h5></Col>
                    <Col xs={8} sm={8} md={8} lg={8}>{this.props.userData.displayName}</Col>
                </Row>
            </ListGroupItem>
            <ListGroupItem className="px-3">
                <Row>
                    <Col xs={4} sm={4} md={4} lg={4}><h5><Translate id="common.email"/></h5></Col>
                    <Col xs={8} sm={8} md={8} lg={8}>{this.props.userData.email} <Button className="btn-light ml-4 text-decoration-underline" onClick={this.state.isActive === true? "" : this.handleSubmit}>{this.state.isActive === true? this.props.translate("common.active") : this.props.translate("common.notActive")}</Button></Col>
                </Row>
            </ListGroupItem>
            <ListGroupItem className="px-3">
                <Row>
                    <Col xs={4} sm={4} md={4} lg={4}><h5><Translate id="common.phone"/></h5></Col>
                    <Col xs={8} sm={8} md={8} lg={8}>{this.props.userData.mobile}</Col>
                </Row>
            </ListGroupItem>
        </ListGroup>

        <Row className="mb-5"></Row>
        <Row className="mb-5"></Row>
      </div>
    );
  }
}

export default withLocalize(Profile);
