import Swal from 'sweetalert2';
import { constants } from 'aiot-room-booking-common';

const show = opts => {
    return Swal.fire(opts).then(result=>{ return result.value});
}

const info = (title, msg, okBtnText) => {
    show({
        title: title,
        text: msg,
        type: constants.PROMPTS.TYPE.INFO,
        confirmButtonText: okBtnText
      })
}

const error = (title, msg, okBtnText) => {
    show({
        title: title,
        text: msg,
        type: constants.PROMPTS.TYPE.ERROR,
        confirmButtonText: okBtnText
      })
}

const warn = (title, msg, okBtnText) => {
    show({
        title: title,
        text: msg,
        type: constants.PROMPTS.TYPE.WARNING,
        confirmButtonText: okBtnText
      })
}

const success = (title, msg, okBtnText) => {
    show({
        title: title,
        text: msg,
        type: constants.PROMPTS.TYPE.SUCCESS,
        confirmButtonText: okBtnText
      })
}

const question = (title, msg, okBtnText, cancelBtnText) => {
    return show({
        title: title,
        text: msg,
        type: constants.PROMPTS.TYPE.QUESTION,
        confirmButtonText: okBtnText,
        cancelButtonText: cancelBtnText,
        showCancelButton: !(!cancelBtnText)
      });
}



export { show, info, error, warn, success, question }