import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';

const Footer = (props) => (
    <Container>
      <Row>
        <Col xs={5} sm={6} md={6} lg={6} className="footer-company">
          <Translate id="common.footer.copyright.copyright"/>
          &copy;{new Date().getFullYear()} <br></br>
          <Translate id="common.company.name"/> 
        </Col>
        <Col xs={7} sm={6} md={6} lg={6} className="footer-contact">
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} className="p-0">
              <Button variant="link" href="/aboutUs" style={{ textDecoration: 'none' }} >
              <div><Translate id="common.footer.copyright.aboutUs"/></div>
              </Button>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className="p-0">
              <Button variant="link" href="/venueBookingCondition" style={{ textDecoration: 'none' }} >
                <div><Translate id="common.footer.copyright.details"/></div>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
);

export default withLocalize(Footer);
