import React, { Fragment, Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Container, Row} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Constants from '../../constants';
import { UserServices } from '../../services';
import { Prompt } from '../../utils';


class Activate extends Component {
    constructor(props) {
      super(props);

      this.state = {
        notificationId: this.props.match.params.notificationId,
        redirectToEvents: false,
        timeout: null,
        userId: null
        //processedResults: null
      }
    }
    

    componentDidMount(){
        this.processNotification();
    }

    componentWillUnmount = () => {
      clearTimeout(this.timeout);
    }

    processNotification=()=>{
      //console.log(this.state.notificationId);
        if (this.state.notificationId) {
            var requestData = {
              notificationId: this.state.notificationId
            };
            UserServices.updateNotification(requestData,this.postProcessNotificationCallback);
        }
    }

    postProcessNotificationCallback=(resp)=>{
        //var state = this.state;
        if (resp && resp.ok) {
          var updatedNotification = resp.data.updatedNotification;
          var requestData = '?email=' + updatedNotification.to;
          UserServices.getUsers(requestData, this.getUsersCallback);
        } else {
          
          Prompt.error('Error',resp.error.message,'OK');

        }
    }

    getUsersCallback=(resp)=>{
      if (resp && resp.ok) {
        var state = {
          userId: resp.data.users[0]._id,
        };
        this.setState(state);

        var requestData = {
              userId: resp.data.users[0]._id
        };
        UserServices.activateUser(requestData,this.postActivateUserCallback);

      } else {
        Prompt.error('Error',resp.error.message,'OK');
      }

    }

    postActivateUserCallback=(resp)=>{
      if (resp && resp.ok) {
        Prompt.success('Success',this.props.translate("common.successMsg.USER_IS_ACTIVE"),'OK');
        var requestData = '?name=Normal';

        UserServices.getUserRoles(requestData, this.getUserRolesCallback);
      } else {
        //console.log(resp);
        Prompt.error('Error',resp.error.message,'OK');
      }
    }

    getUserRolesCallback=(resp)=>{
      //console.log(resp);
      if (resp && resp.ok) {
        
        var requestData = {
            userId: this.state.userId,
            roles: resp.data.userRoles[0]._id
          }
        UserServices.updateUser(requestData, this.postUpdateUsersCallback);

      } else {
        Prompt.error('Error',resp.error.message,'OK');

      }
    }

    
    postUpdateUsersCallback=(resp)=>{

      if (resp && resp.ok) {
          localStorage.setItem(Constants.SESSION_KEY_USER_DATA, JSON.stringify(resp.data.authuser));
          this.props.userHasLoggedIn(true);
          this.props.setUser(resp.data.authuser);
          
          this.timeout = setTimeout(() => this.setState({ redirectToEvents: true }), 5000);
      
      } else {
          Prompt.error('Error',resp.error.message,'OK');
      }

    }
          

    render() {
        
        if (this.state.redirectToEvents === true) {
            return <Redirect to={Constants.CONTEXT_PATH} />;
        }
    
        return (
        <Fragment><link rel="stylesheet" href={Constants.CONTEXT_PATH+"css/activate.css?t="+Constants.VERSION}></link>
        <Container className="activate main-container">

        <Row/>
        <Row/>
        <Row/>
        <Translate id="register.activate.processing" />
        <Row/>
        <Row/>
        <Translate id="register.activate.greeting" />


        </Container></Fragment>
        );
    };
}

export default withLocalize(Activate);


    