const _ = require('lodash');

const Constants = {
    VERSION: _.defaultTo(process.env.REACT_APP_VERSION,'0.0'),
    SESSION_KEY_USER_DATA: 'USER_DATA',
    ACTIVE_LANG_CODE: 'LANG_CODE',
    SUPPORTED_LANGS: [{ name: 'EN', code: 'en' } ,{ name: '简', code: 'sc' },{ name: '繁', code: 'zh' }],
    CONTEXT_PATH: _.defaultTo(process.env.REACT_APP_CONTEXT_PATH, '/'),
    DOMAIN: process.env.REACT_APP_DOMAIN,
    INPUT_WAIT_INTERVAL: 1000,
    PAYMENT_TIMEOUT: process.env.REACT_APP_PAYMENT_TIMEOUT,
    PAYMENT_HOMEPAGE: {
        PAYME: process.env.REACT_APP_PAYME_HOMEPAGE
    },
    SUPPORT: {
        ENABLE: String(_.defaultTo(process.env.REACT_APP_SUPPORT_ENABLE,'true').toLowerCase())==='true',
        AMOUNT: {   MIN:_.defaultTo(process.env.REACT_APP_SUPPORT_MIN_AMOUNT, 10), 
                    MAX:_.defaultTo(process.env.REACT_APP_SUPPORT_MAX_AMOUNT, 500)
                },
        RESULT: {   SUCCESS: "success", FAILURE: "failure" },
        CHECK_PAYMENT_STATUS_INTERVAL: _.defaultTo(process.env.REACT_APP_CHECK_PAYMENT_STATUS_INTERVAL,1000)
    },
    APP_ID: { 
        FB: process.env.REACT_APP_APPID_FB,
        YOUTUBE_CHANNEL_ID: process.env.REACT_APP_YOUTUBE_CHANNEL_ID,
        GOOGLE_ADSENSE_ID: process.env.REACT_APP_GOOGLE_ADSENSE_ID,
        GA_TRACKING_CODE: process.env.REACT_APP_GA_TRACKING_CODE,
        GOOGLE_RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
        GOOGLE_RECAPTCHA_SECRET_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY
    },
    API: {
        AUTH_SERVICES: process.env.REACT_APP_API_AUTH_SERVICES,
        ROOM_BOOKING_SERVICES: process.env.REACT_APP_API_ROOM_BOOKING_SERVICES,
        USER_SERVICES: process.env.REACT_APP_API_USER_SERVICES,
        //GOOGLE_RECAPTCHA_SERVICES: process.env.REACT_APP_API_GOOGLE_RECAPTCHA_SERVICES,
        PAY_SERVICES: process.env.REACT_APP_API_PAY_SERVICES,
        EMAIL_SERVICES: process.env.REACT_APP_API_EMAIL_SERVICES,
        FETCH_INTERVAL: 5000
    }
}

module.exports = Constants;