//import Constants from "../constants";
const axios = require('axios');
const socketIOClient = require("socket.io-client");

export const Commons = {
	get, post, del, connect, join, on, emit, disconnect
}

function del(service, payload, callback) {
	axios.delete(service, {headers: {"Authorization" : 'Bearer '+payload.token} })
	.then(function (response) {
		// handle success
		//console.log(response);
		if (callback) {
			callback(response.data);
		} else {
			return response.data;
		}
	})
	.catch(function (error) {
		// handle error
		//console.log(error);
		callback(error.response.data);
	})
	.finally(function () {
		// always executed
	});
}

function get(service, callback, payload) {
	//console.log(service);
	var options = {};
	if (payload && payload.token) {
		options = {headers: {"Authorization" : 'Bearer '+payload.token} };
	}
	axios.get(service, options)
	.then(function (response) {
		// handle success
		//console.log(response);
		if (callback) {
			callback(response.data);
		} else {
			return response.data;
		}
	})
	.catch(function (error) {
		// handle error
		//console.log(error);
		if (error.response && error.response.data) {
			callback(error.response.data);
		}
		else if (error.response) {
			callback(error.response);
		}
		else {
			callback(error);
		}
	})
	.finally(function () {
		// always executed
	});
}

function post(service, requestData, callback) {
	var options = {};
	if (requestData && requestData.token) {
		options = {headers: {"Authorization" : 'Bearer '+requestData.token} };
		requestData.token='';
	}
	axios.post(service, requestData, options)
	.then(function (response) {
	    //console.log(response);
		callback(response.data);
	})
	.catch(function (error) {
		//console.log(error);
		if (error.response && error.response.data) {
			callback(error.response.data);
		}
		else if (error.response) {
			callback(error.response);
		}
		else {
			callback(error);
		}
	})
	.finally(function () {
		// always executed
	});
}

function connect(service) {

	const socket = socketIOClient(service, {
		'reconnection': true,
		'reconnectionDelay': 2000,
		'reconnectionAttempts': 10000
	});
	return socket;

}

function join(socket, event, eventName) {

	socket.emit(event, eventName);


}

function on(socket, event, callback) {

	socket.on(event, callback);


}

function emit(socket, event, data) {

	socket.emit(event, data);


}

function disconnect(socket) {

	socket.disconnect();


}

