import Constants from '../constants';
import { Commons } from './Commons';

const serviceUrl = Constants.API.AUTH_SERVICES;

export {
  login, fbLogin, logout, authrecaptcha, validate
};

function login(requestData, callback) {
  var callingService = serviceUrl+'authuser';
  if (requestData.userId) {
    callingService += '/'+requestData.userId;
  }
  //console.log('calling '+callingService);
  Commons.post(callingService, requestData, callback);
}

function fbLogin(requestData, callback) {
  var callingService = serviceUrl+'authfbuser';
  //console.log('calling '+callingService);
  Commons.post(callingService, requestData, callback);
}

function logout(requestData, callback) {
  var callingService = serviceUrl+'delusertoken/'+requestData.user;
  Commons.del(callingService, requestData, callback);
}

function authrecaptcha(requestData, callback) {
  var callingService = serviceUrl+'authrecaptcha';
  //console.log('calling '+callingService);
  Commons.post(callingService, requestData, callback);
}

function validate(requestData, callback) {
  var callingService = serviceUrl+'validate';
  Commons.get(callingService, callback, requestData);
}
