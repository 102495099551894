import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Constants from '../constants';

export default ({ component: C, props: cProps, ...rest }) => {
  
  if (cProps && !cProps.isLoggedIn) {
    window.localStorage.removeItem(Constants.SESSION_KEY_USER_DATA);
  }

  return (
    <Route
      {...rest}
      render={props =>
        cProps.isLoggedIn ? (
          <C {...props} {...cProps} />
        ) : (
          <Redirect
            to={{
              pathname: Constants.CONTEXT_PATH + 'login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
