import React, { Component } from 'react';
import { withLocalize, /*Translate*/ } from 'react-localize-redux';

class AboutUs extends Component {
    /*constructor(props) {
      super(props);
    }*/

    render() {
        return (
            <div className='text-left'>
                <h1>iShow 背景</h1>
                <p>iShow致力發展空間出租的業務，目的希望為各位用戶提供一個選擇最豐富的娛樂資訊平台，為用户提供各種類型的時租空間的資訊分享。用戶亦可按需要預訂各類型嘅場地，購買自己感興趣嘅產品，希望用戶能夠享受屬於自己的快樂空間。</p>
                
                <h1>展望未來</h1>
                <p>與更多場地合作，預訂更多類型嘅產品及服務，提供更豐富娛樂資訊。聯繫及滿足更多個人、團體或公司聚會及活動，同時​積極發展線下銷售平台。</p>
                <div style={{marginBottom: '120px'}}></div>
            </div>
        );
    }


}

export default withLocalize(AboutUs);